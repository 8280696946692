import React, { useState, useEffect } from 'react'
import { Box, Card, Grid, Toolbar, IconButton, LinearProgress, Button, Modal, Fab, Popover, Typography, Checkbox, Divider, Drawer, Hidden, Dialog, DialogTitle, DialogContent } from '@mui/material'
import { ArrowBackIos, Close } from '@mui/icons-material'
import { styleModal3D } from '../../config'
import { useNavigate, useParams } from 'react-router-dom'
import { MVAvatar, PautaDetail } from '../../containers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faBars } from '@fortawesome/free-solid-svg-icons'
import { useAuth, useConnectionContext, useExecutionReportContext, useReportsContext } from '../../context'
import { PreviewDialog } from '../../dialogs'
import { MenuActividades } from './MenuActividades'

const ActivitiesDetailPage = () => {
    const {isOperator, userData} = useAuth()
    const {isOnline} = useConnectionContext()
    const navigate = useNavigate()
    const {id} = useParams()
    const {
        report, 
        setReport, 
        executionReport, 
        setOtIndex,
        messageOnReport, 
        ejecucionActualizada, 
        resutProgress,
        resultThisItemProgress,
        progress,
        itemProgress,
        openMenu,
        setOpenMenu,
        ejecucion,
        setEjecucion
        
    } = useExecutionReportContext()
    const {guardarContadorEnReporte} = useReportsContext()
    const [ smActivated, setSmActivated ] = useState(false)
    const [ subSistem, setSubSistem ] = useState()
    const [ open3D, setOpen3D ] = useState(false)
    const [ ultimoGuardadoDispositivo, setUltimoGuardadoDispositivo ] = useState()
    const [openPreviewModal, setOpenPreviewModal] = useState(false)
    const [paginaOcultada, guardarPaginaOcultada] = useState(false)
    const [soloUnEnvio, setSoloUnEnvio] = useState(true)
    const [filterData, setFilterData] = useState(
        {
            todo: true,
            sinAccion: true,
            sinEjecutar: true,
            ejecutado: true,
            backlogList: false
        }
    )
    const [executionReportFiltered, setExecutionReportFiltered] = useState()


    /* NUEVA VERSION */

    useEffect(() => {
        if(ejecucionActualizada) {
            console.log('Ejecución ha cambiado, ', ejecucionActualizada)
            if (ejecucion.inicio) {
                guardarContadorSelect(false)
            }
        }
    },[ejecucionActualizada])

    const guardarContadorSelect = async (state) => {
        await guardarContador(state)
    }

    useEffect(() => {
        if (!soloUnEnvio) {
            setTimeout(() => {
                setSoloUnEnvio(true)
            }, 5000);
        }
    },[soloUnEnvio])

    const filtering = async () => {
        console.log(executionReport)
        const executionReportFilteredCache = executionReport
        const group = {}
        if (executionReport && executionReport.group) {
            Object.keys(executionReport.group).forEach(async (key) => {
                if (!group[key]) {
                    group[key] = []
                }
                executionReport.group[key].forEach(item => {
                    if (filterData.todo) {
                        group[key].push(item)
                    } else if(filterData.backlogList) {
                        if(item && item.backlogList && item.backlogList.length > 0) {
                            group[key].push(item)
                        }
                    }
                })
            })
        }
        executionReportFilteredCache.group = group
        setExecutionReportFiltered(executionReportFilteredCache)
    }

    useEffect(() => {
        if (!report && id) {
            setOtIndex(id)
        }
    }, [report, id])

    useEffect(() => {
        if (report && (!report.level || (report.level < 1))&&(isOperator)&&(userData._id === report.usersAssigned[0]._id)) {
            const ejecucionCache = ejecucion
            ejecucionCache.userId = userData._id
            ejecucionCache.inicio = new Date()
            setEjecucion(ejecucionCache)
            document.addEventListener("visibilitychange", async () => {
                if (document.hidden) {
                    await guardarContador(false)
                    guardarPaginaOcultada(true)
                    
                } else {
                    if (paginaOcultada) {
                        /* if (stateVisibility) { */
                            ejecucionCache.userId = userData._id
                            ejecucionCache.inicio = new Date()
                            setEjecucion(ejecucionCache)
                            guardarPaginaOcultada(false)
                        /* } */
            
                    }
                }
            }, false);
        } else {
            console.log('***************************** falso')
        }
    },[report, isOperator])

    const setProgress = ({progressPage, globalProgress}) => {
        resutProgress(Number(progressPage))
        resultThisItemProgress(Number(globalProgress))
    }

    const guardarContador = (nav) => {
        console.log(nav)
        return new Promise(async resolve => {
            const ejecucionCache = ejecucion
            if (ejecucionCache.inicio) {
                ejecucionCache.termino = new Date()
                if (report) {
                    const reportCache = report
                    if (!reportCache.ejecuciones) {
                        reportCache.ejecuciones = []
                    } else {
                        const res = reportCache.ejecuciones.findIndex(item => item.inicio === ejecucionCache.inicio)
                        if (res === -1) {
                            reportCache.ejecuciones.push(ejecucionCache)
                        } else {
                            reportCache.ejecuciones[res] = ejecucionCache
                        }
                        if (isOnline) {
                            guardarContadorEnReporte(reportCache)
                        }
                    }
                    setReport(reportCache)
                }
            }
            if (nav) {
                console.log(nav)
                navigate(-1)
                setEjecucion({
                    inicio: null,
                    termino: null,
                    userId: null
                })
            }
            resolve(true)
        })
    }

    const closeModal = () => {
        setOpen3D(false)
    }

    const closePreviewModal = () => {
        setOpenPreviewModal(false)
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const idPopover = open ? 'simple-popover' : undefined;

    const executeFilter = (filter) => {
        setFilterData(filter)
    }

    const SelectionPopover = () => {

        const [todo, setTodo] = useState(true)
        const [sinAccion, setSinAccion] = useState(true)
        const [sinEjecutar, setSinEjecutar] = useState(true)
        const [ejecutado, setEjecutado] = useState(true)
        const [backlogList, setBacklogList] = useState(false)

        useEffect(() => {
            setTodo(filterData.todo)
            setSinAccion(filterData.sinAccion)
            setSinEjecutar(filterData.sinEjecutar)
            setEjecutado(filterData.ejecutado)
            setBacklogList(filterData.backlogList)
        },[filterData])

        useEffect(() => {
            if (!sinAccion || !sinEjecutar || !ejecutado) {
                setTodo(false)
            }
            if (sinAccion && sinEjecutar && ejecutado) {
                if (!filterData.backlogList) {
                    setTodo(true)
                }
                setBacklogList(filterData.backlogList)
            }
        },[sinAccion, sinEjecutar, ejecutado])

        useEffect(() => {
            if(todo) {
                if(!sinAccion) {
                    setSinAccion(true)
                }
                if(!sinEjecutar) {
                    setSinEjecutar(true)
                }
                if (!ejecutado) {
                    setEjecutado(true)
                }
                if (backlogList) {
                    setBacklogList(false)
                }
            }
        }, [todo])

        useEffect(() => {
            if(backlogList) {
                if (todo) {
                    setTodo(false)
                }
            }
        }, [backlogList])

        const setFilter = () => {
            const data = {
                todo: todo,
                sinAccion: sinAccion,
                sinEjecutar: sinEjecutar,
                ejecutado: ejecutado,
                backlogList: backlogList
            }
            executeFilter(data)
            handleClose()
        }

        return (
            <Popover
                id={idPopover}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                >
                <div style={{width: 200}}>
                    <p>
                        <Checkbox checked={todo} onChange={e => setTodo(e.target.checked)}/> Todo
                    </p>
                    <p>
                        <Checkbox checked={sinAccion} onChange={e => setSinAccion(e.target.checked)}/> Sin acciones
                    </p>
                    <p>
                        <Checkbox checked={sinEjecutar} onChange={e => setSinEjecutar(e.target.checked)}/> Sin ejecutar
                    </p>
                    <p>
                        <Checkbox checked={ejecutado} onChange={e => setEjecutado(e.target.checked)}/> Ejecutados
                    </p>
                    <Divider />
                    <p>
                        <Checkbox checked={backlogList} onChange={e => setBacklogList(e.target.checked)}/> Backlog
                    </p>
                    <Divider />
                    <div style={{textAlign: 'center', margin: '10px 0px'}}>
                        <Button variant={'contained'} onClick={setFilter}>
                            Filtrar
                        </Button>
                    </div>
                </div>
            </Popover>
        )
    }

    return (
        <Box height='80vh'>
            <MenuActividades 
             openMenu={openMenu}
             setOpenMenu={setOpenMenu}
             id={id}
            />
            <Grid className={'root'} container spacing={0}>
                <Grid className={'pageContainer'} item xs={12}>
                    <Card elevation={0} className={'pageCard'}>
                        <Grid container alignItems='center' justifyContent='center'>
                            <div style={{width: '100%', textAlign: 'left', padding: 10 }}>
                                <div style={{width: '100%', textAlign: 'left', color: '#333', backgroundColor: '#fff', borderRadius: 20 }}>
                                    <Toolbar style={{paddingLeft: 0, backgroundColor: '#F9F9F9', borderRadius: 10, width: '100%', position: 'relative'}}>
                                        <IconButton onClick={async () => await guardarContador(true)}> 
                                            <ArrowBackIos style={{color: '#333', fontSize: 16}}/> 
                                        </IconButton> 
                                        <Hidden mdDown>
                                            <h1 style={{marginTop: 0, marginBottom: 0, fontSize: 16}}>
                                                Actividades Asignadas / Detalle / OT {id} <strong>{(report && report.testMode) ? 'Modo test' : ''}</strong>
                                            </h1>
                                        </Hidden>
                                        <Hidden mdUp>
                                            <h1 style={{marginTop: 0, marginBottom: 0, fontSize: 16}}>
                                                OT {id} <strong>{(report && report.testMode) ? 'Modo test' : ''}</strong>
                                            </h1>
                                        </Hidden>
                                        <IconButton
                                            style={{position: 'absolute', right: 70}}
                                            color={'primary'} 
                                            edge={'end'}
                                            title='Filtrar'
                                            aria-describedby={idPopover} 
                                            onClick={handleClick}
                                        >
                                            <FontAwesomeIcon style={{zIndex: 10}} icon={faFilter}/>
                                        </IconButton>
                                        <IconButton
                                            style={{position: 'absolute', right: 30}}
                                            color={'primary'} 
                                            edge={'end'}
                                            title='Filtrar'
                                            aria-describedby={idPopover} 
                                            onClick={() => {setOpenMenu(true)}}
                                        >
                                            <FontAwesomeIcon style={{zIndex: 10}} icon={faBars}/>
                                        </IconButton>
                                        <SelectionPopover />
                                    </Toolbar>
                                </div>
                            </div>
                            <Grid container style={{position: 'relative'}}>
                                <Hidden smDown>
                                <div style={{position: 'absolute', height: 30, width: '35vw', right: 20, bottom: 10, border: '1px solid #ccc', borderRadius: 10, padding: '0px 5px'}}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                        <p style={{textAlign: 'center', fontSize: 12, margin: '5px 0px'}}><strong>Avance de hoja: {progress}%</strong></p>
                                        </Grid>
                                        <Grid item xs={2}>
                                        <LinearProgress variant="determinate" value={progress} style={{width: '100%', marginTop: 13}}/>   
                                        </Grid>
                                        <Grid item xs={4}>
                                        <p style={{textAlign: 'center', fontSize: 12, margin: '5px 0px'}}><strong>Avance total: {itemProgress}%</strong></p>
                                        </Grid>
                                        <Grid item xs={2}>
                                        <LinearProgress variant="determinate" value={itemProgress} style={{width: '100%', marginTop: 13}}/>
                                        </Grid>
                                    </Grid>
                                </div>
                                </Hidden>
                                <Grid item xs={12}>
                                    <div style={{width: '100%', height: 'calc(100vh - 190px)', padding: 5}}>
                                        {
                                            (executionReport && executionReport._id) ? <PautaDetail 
                                                filterData={filterData}
                                                height={smActivated ? 'calc(100vh - 850px)' :'calc(100vh - 360px)'} 
                                                report={report} 
                                                setProgress={setProgress} 
                                                resultThisItemProgress={resultThisItemProgress}
                                                /* selectionItem={selectionItem} */
                                                setUltimoGuardadoDispositivo={setUltimoGuardadoDispositivo}
                                                /* executionReport={executionReport} */
                                            /> : 
                                            <div
                                                style={{
                                                    width: '100%',
                                                    lineHeight: smActivated ? 'calc(100vh - 850px)' :'calc(100vh - 360px)',
                                                    height: smActivated ? 'calc(100vh - 850px)' :'calc(100vh - 360px)',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        lineHeight:' 1.5',
                                                        display: 'inline-block',
                                                        verticalAlign: 'middle',
                                                        fontSize: 25
                                                    }}
                                                >
                                                    {messageOnReport}
                                                </p>
                                            </div>
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            {
                openPreviewModal && <PreviewDialog open={openPreviewModal} closePreviewModal={closePreviewModal} />
            }
            <Modal
                open={open3D}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal3D}>
                    <MVAvatar subSistem={subSistem} />
                    <Fab onClick={() => closeModal()} style={{position: 'absolute', right: 10, top: 10, boxShadow: 'none', backgroundColor: 'transparent'}} color="primary">
                        <Close />
                    </Fab>
                </Box>
                
            </Modal>
        </Box>
    )
}

export default ActivitiesDetailPage

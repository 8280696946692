import { useState } from "react"
import { useAuth, useExecutionReportContext } from "../../context"
import { detectIf3DModelExist, translateSubSystem } from "../../config"
import { Button, Drawer, LinearProgress } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faComment, faEye, faPaperPlane, faWrench } from "@fortawesome/free-solid-svg-icons"
import { useParams } from "react-router-dom"
import { Close } from "@mui/icons-material"

export const MenuActividades = ({openMenu, setOpenMenu, id}) => {
    const {admin} = useAuth()
    const {
        report, 
        executionReport, 
        setOtIndex, 
        sapId, 
        serieEquipo, 
        setLoadingMessage, 
        messageOnReport, 
        ejecucionActualizada, 
        machineData, 
        habilite3D,
        puedeEnviar,
        puedeCerrar,
        puedeRechazar,
        puedeTerminarJornada,
        totalBacklogs,
        progress,
        itemProgress,
        forwardReport,
        rejectReport,
        vistaPrevia,
        openMessages,
        closeMessages,
        terminarJornada,
        openBacklogsResume,
        setIsTermJornada,
        setOpenReportCommitModal
    } = useExecutionReportContext()

    const mensajeTerminoJornada = () => {
        setIsTermJornada(true)
        setOpenReportCommitModal(true)
        setOpenMenu(false)
    }

    return (
        <Drawer open={openMenu} onClose={() => {setOpenMenu(false)}} anchor='right'>
            <div style={{width: 250, height: '100%'}}>
                <div style={{padding: 10, backgroundColor: '#F9F9F9', height: '100%', overflowY: 'auto'}}>
                    <div style={{textAlign: 'center', width: '100%'}}>
                        <h2 style={{margin: 0}}>OT {id}</h2>
                        <p style={{margin: 0}}>{report && report.reportType === 'Mantención' ? 'OM' : 'Aviso'} SAP: <strong>{sapId && sapId}</strong></p>
                        <p style={{margin: 0}}>Serie Equipo: <strong>{serieEquipo && serieEquipo.replace('00000000', '')}</strong></p>
                        <p style={{margin: 0}}>Tipo de pauta: <strong>{report && report.guide}</strong></p>
                        <p style={{margin: 0}}>Flota: <strong>{report && `${report.maquina.type} ${report.maquina.model}`} </strong></p>
                        <p style={{margin: 0}}>Total backlogs: <strong>{totalBacklogs}</strong></p>
                        {(report && report.testMode) && <p style={{backgroundColor: 'red', color: 'white', marginBottom: 0}}><strong>Modo Test</strong></p>}
                        {machineData && <p style={{margin: 0}}>Modelo Máquina {machineData.model}, N°{machineData.equ}</p>}
                        {habilite3D && <Button variant="contained" disabled={!habilite3D} style={{paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10}} onClick={()=>{setOpen3D(true)}}><strong>3D</strong></Button>}
                    </div>
                    <div style={{margin: '20px 0px 10px 0px'}}>
                        <p style={{textAlign: 'center', fontSize: 16, margin: '10px 0px'}}>Avance en esta hoja: {progress}%</p>
                        <LinearProgress variant="determinate" value={progress} style={{width: '100%'}}/>   
                        <p style={{textAlign: 'center', fontSize: 16, margin: '10px 0px'}}>Avance total: {itemProgress}%</p>
                        <LinearProgress variant="determinate" value={itemProgress} style={{width: '100%'}}/>
                    </div>
                    <br />
                    {puedeEnviar && <Button variant="contained" color='primary' style={{padding: 10, width: '100%', marginBottom: 20}} onClick={forwardReport}>
                        <FontAwesomeIcon icon={faPaperPlane} style={{marginRight: 10}} /> Enviar OT
                    </Button>}
                    {puedeCerrar  && <Button variant="contained" color='primary' style={{padding: 10, width: '100%', marginBottom: 20}} onClick={forwardReport}>
                        <FontAwesomeIcon icon={faPaperPlane} style={{marginRight: 10}} /> Cerrar OT
                    </Button>
                    }
                    {(puedeCerrar || admin) && <Button variant="contained" color='primary' style={{padding: 10, width: '100%', marginBottom: 20}} onClick={vistaPrevia}>
                        <FontAwesomeIcon icon={faEye} style={{marginRight: 10}} /> Resumen Insumos/Materiales
                    </Button>
                    }
                    {puedeRechazar &&
                    <Button variant="contained" color='primary' style={{padding: 10, width: '100%', marginBottom: 20}} onClick={rejectReport}>
                        <Close />
                        Rechazar OT
                    </Button>}
                    {puedeTerminarJornada && <Button onClick={mensajeTerminoJornada} variant="contained" color='primary' style={{padding: 10, width: '100%', marginBottom: 20}}>
                        <FontAwesomeIcon icon={faClock} style={{marginRight: 10}} /> Terminar Jornada
                    </Button>}
                    <Button variant="contained" color='primary' style={{padding: 10, width: '100%', marginBottom: 20}} onClick={openMessages}>
                        <FontAwesomeIcon icon={faComment} style={{marginRight: 10}} /> Mensajes de flujo
                    </Button>
                    <Button variant="contained" color='primary' style={{padding: 10, width: '100%', marginBottom: 20}} onClick={openBacklogsResume}>
                        <FontAwesomeIcon icon={faWrench} style={{marginRight: 10}} /> Resumen Backlogs
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}
import { Button, Dialog, IconButton, Slide } from "@mui/material"
import { Close } from "@mui/icons-material"
import { forwardRef, useEffect, useState } from "react";
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const InformacionDialog = ({open, handleClose, data}) => {

    const [totalPautas, setTotalPautas] = useState(0)
    const [totalMaquinas, setTotalMaquinass] = useState(0)
    const [infoPautas, setInfoPautas] = useState(false)
    const [infoMaquinas, setInfoMaquinas] = useState(false)

    useEffect(() => {
        console.log(data)
        setTotalPautas(data.pautasDescargadas)
        setTotalMaquinass(data.maquinasDescargadas)
    },[data])

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <IconButton
                    style={{position: 'absolute', top: 20, right: 20, backgroundColor: '#fff'}}
                    onClick={handleClose}
                    >
                    <Close />
                </IconButton>
                <div style={{padding: 30, textAlign: 'left', height: '50vh', width: '50vh', overflowY: 'auto'}}>
                    <div style={{width:'100%', textAlign: 'center'}}>
                        <h3>Información del sistema</h3>
                    </div>
                    <p>Pautas descargadas: {totalPautas} <button 
                                                                onClick={() => {setInfoPautas(infoPautas ? false : true)}}
                                                                style={{
                                                                    borderRadius: '50%', 
                                                                    marginLeft: 10,
                                                                    backgroundColor: infoPautas ? 'grey' : 'silver'
                                                                }}
                                                                >?</button></p>
                    {
                        infoPautas &&
                        <p style={{fontSize: 10}}>
                            Corresponde a la totalidad de <strong>pautas</strong> que se obtienen desde la base de datos.
                        </p>
                    }
                    <p>Maquinas descargadas: {totalMaquinas} <button 
                                                                onClick={() => {setInfoMaquinas(infoMaquinas ? false : true)}}
                                                                style={{
                                                                    borderRadius: '50%', 
                                                                    marginLeft: 10,
                                                                    backgroundColor: infoMaquinas ? 'grey' : 'silver'
                                                                }}
                                                                >?</button></p>
                    {
                        infoMaquinas &&
                        <p style={{fontSize: 10}}>
                            Corresponde a la totalidad de <strong>máquinas</strong> que se obtienen desde la base de datos.
                        </p>
                    }
                </div>
            </Dialog>
        </div>
    )
}

export default InformacionDialog
import {useState, useEffect} from 'react'
import {Grid, Toolbar, IconButton, TablePagination, Typography, Box, Card, ListItemButton, ListItemText, Chip, List, Collapse, ListItemIcon} from '@mui/material';
import { ArrowBackIos, ExpandLess, ExpandMore, StarBorder } from '@mui/icons-material';
import { Mantenciones, Inspecciones } from './ReportsListLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faCircle, faClipboardList, faCalendar, faDownload, faPlus, faInfoCircle, faMapMarker, faChartBar, faTrash } from '@fortawesome/free-solid-svg-icons';
import './reports.css'
import { ReportsList } from '../../containers';
import { useNavigate } from 'react-router-dom';
import { useAuth, useReportsContext, useSitesContext } from '../../context';
import * as XLSX from 'xlsx'
import { ReportsResumeDialog } from '../../dialogs';
import SeleccionarObraDialog from '../../dialogs/SeleccionarObraDialog';
import ReportsKPIDialog from '../../dialogs/ReportsKPIDialog';
import { descargarDataCompilada } from '../../routes/databaseConnection.routes';

const ReportsPage = () => {

    const {sites} = useSitesContext()
    const {admin, isSapExecutive, isShiftManager} = useAuth()
    const {reports, setListSelected, listSelected, listSelectedCache, setListSelectedCache, getReports, statusReports, pautas, siteSelected, borrarListaReportes} = useReportsContext()
    const [inspeccionesTotales, setInspeccionesTotales] = useState([])
    const [mantencionesTotales, setMantencionesTotales] = useState([])
    const [ inspecciones, setInspecciones ] = useState([]);
    const [ mantenciones, setMantenciones ] = useState([]);
    const [ hableCreateReport, setHableCreateReport ] = useState(false);
    const [ inspeccionesCompletadas, setInspeccionesCompletadas ] = useState(0);
    const [ mantencionesPorAsignar, setMantencionesPorAsignar ] = useState(0);
    const [ mantencionesCompletadas, setMantencionesCompletadas ] = useState(0);
    const [ vista, setVista ] = useState(true);
    const [ rowsPerPage, setRowsPerPage ] = useState(10)
    const [ page, setPage]  = useState(0);
    const [ listToShow, setListToShow ] = useState([])
    const [ listToShowCache, setListToShowCache ] = useState([])
    const [ totalItems, setTotalItems ] = useState(0)
    const [allItemsSelected, setAllItemsSelected] = useState(false)
    const [site, setSite] = useState('nada')
    const [typeReportsSelected, setTypeReportsSelected] = useState('')
    const navigate = useNavigate()
    const [ flechaListaxOT, setFlechaListaxOT ] = useState(faArrowUp)
    const [canOpenNewReport, setCanOpenNewReport] = useState(false)
    const [openReportSumary, setOpenReportSumary] = useState(false)
    const [openSeleccionarObra, setOpnSeleccionarObra] = useState(false)
    const [opnKPI, setOpnKPI] = useState(false)
    const [siteObject, setSiteObject] = useState()
    const [tipo, setTipo] = useState()
    const [grupoReportes, setGrupoReportes] = useState()

    useEffect(() => {
        if (sites.length > 0 && siteSelected) {
            const siteFiltered = sites.filter(site => {if (site.idobra === siteSelected) return site})[0]
            setSiteObject(siteFiltered)
        }
    },[siteSelected])

    useEffect(() => {
        if (pautas.length === 0) {
            setCanOpenNewReport(false)
        } else {
            setCanOpenNewReport(true)
        }
    }, [pautas])

    useEffect(() => {
        if (listSelected.length > 0) {
            initReadList()
        } else {
            setListToShow([])
            setListToShowCache([])
        }
    }, [listSelected, rowsPerPage])

    useEffect(() => {
        if (admin || isSapExecutive) {
            setHableCreateReport(true)
        }
    }, [admin, isSapExecutive])

    useEffect(() => {
        if (reports.length > 0) {
            const inspeccionesTemp = reports.filter(report => {
                if (report.reportType === "Inspección") {
                    report.estado = levelToState(report.level, report.usersAssigned, report.state)
                    return report
                }
            })
            
            const mantencionesTemp = reports.filter(report => {
                if (report.reportType === "Mantención") {
                    report.estado = levelToState(report.level, report.usersAssigned, report.state)
                    return report
                }
            })
            setInspecciones(Inspecciones)
            setMantenciones(Mantenciones)
            setInspeccionesTotales(inspeccionesTemp.sort((a, b) => {
                return a.idIndex - b.idIndex
            }))
            setMantencionesTotales(mantencionesTemp)
        } else {
            setInspecciones(Inspecciones)
            setMantenciones(Mantenciones)
            setInspeccionesTotales([])
            setMantencionesTotales([])
        }
    }, [reports])

    useEffect(() => {
        if (inspeccionesTotales.length > 0) {
            const inspeccionesOrigen = inspeccionesTotales.filter(inspeccion => {
                if(inspeccion.origen) {
                    return inspeccion
                }
            })
            const inspeccionesSinAsignar = inspeccionesTotales.filter(inspeccion => {
                if((inspeccion.state==='Asignar' && (inspeccion.usersAssigned.length === 0)) && (!inspeccion.origen)) {
                    return inspeccion
                }
            })
            const inspeccionesEnProceso = inspeccionesTotales.filter(inspeccion => {
                if(inspeccion.usersAssigned.length > 0 && (inspeccion.state==='En proceso' || inspeccion.state === 'Asignar') && (inspeccion.level < 3 && (inspeccion.level > 0 || !inspeccion.level))) {
                    return inspeccion
                }
            })
            const inspeccionesPorCerrar = inspeccionesTotales.filter(inspeccion => {
                if(inspeccion.level === 3) {
                    return inspeccion
                }
            })
            const inspeccionesCerradas = inspeccionesTotales.filter(inspeccion => {
                if(inspeccion.level === 4) {
                    return inspeccion
                }
            })
            const inspeccionesTable = [...Inspecciones]
            inspeccionesTable[0].number = inspeccionesCerradas.length
            inspeccionesTable[1].number = inspeccionesPorCerrar.length
            inspeccionesTable[2].number = inspeccionesEnProceso.length
            inspeccionesTable[3].number = inspeccionesSinAsignar.length
            inspeccionesTable[4].number = inspeccionesOrigen.length
            inspeccionesTable[0].lista = inspeccionesCerradas
            inspeccionesTable[1].lista = inspeccionesPorCerrar
            inspeccionesTable[2].lista = inspeccionesEnProceso
            inspeccionesTable[3].lista = inspeccionesSinAsignar
            inspeccionesTable[4].lista = inspeccionesOrigen
            inspeccionesTable.forEach((item) => {
                item.open = false
                const itemGrupo = {}
                item.lista.forEach((report) => {
                    if (!itemGrupo[report.estado]) {
                        itemGrupo[report.estado] = []
                    }
                    itemGrupo[report.estado].push(report)
                })
                item.grupos = itemGrupo
            })

            setInspecciones(inspeccionesTable)
        } else {
            const inspeccionesTable = [...Inspecciones]
            inspeccionesTable[0].number = 0
            inspeccionesTable[1].number = 0
            inspeccionesTable[2].number = 0
            inspeccionesTable[3].number = 0
            inspeccionesTable[4].number = 0
            inspeccionesTable[0].lista = []
            inspeccionesTable[1].lista = []
            inspeccionesTable[2].lista = []
            inspeccionesTable[3].lista = []
            inspeccionesTable[4].lista = []
            setInspecciones(inspeccionesTable)
        }
    },[inspeccionesTotales])

    useEffect(() => {
        if (mantencionesTotales.length > 0) {
            const mantencionesOrigen = mantencionesTotales.filter(mantencion => {
                if(mantencion.origen) {
                    return mantencion
                }
            })
            const mantencionesSinAsignar = mantencionesTotales.filter(mantencion => {
                if((mantencion.state==='Asignar' && (mantencion.usersAssigned.length === 0)) && (!mantencion.origen)) {
                    return mantencion
                }
            })
            const mantencionesEnProceso = mantencionesTotales.filter(mantencion => {
                if(mantencion.usersAssigned.length > 0 && (mantencion.state==='En proceso' || mantencion.state==='Asignar') && (mantencion.level < 3 && (mantencion.level > 0 || !mantencion.level)) && (!mantencion.origen)) {
                    return mantencion
                }
            })
            const mantencionesPorCerrar = mantencionesTotales.filter(mantencion => {
                if(mantencion.level === 3 && (!mantencion.origen)) {
                    return mantencion
                }
            })
            const mantencionesCerradas = mantencionesTotales.filter(mantencion => {
                if(mantencion.level === 4 && (!mantencion.origen)) {
                    return mantencion
                }
            })
            const mantencionesTable = [...Mantenciones]
            mantencionesTable[0].number = mantencionesCerradas.length
            mantencionesTable[1].number = mantencionesPorCerrar.length
            mantencionesTable[2].number = mantencionesEnProceso.length
            mantencionesTable[3].number = mantencionesSinAsignar.length
            mantencionesTable[4].number = mantencionesOrigen.length
            mantencionesTable[0].lista = mantencionesCerradas
            mantencionesTable[1].lista = mantencionesPorCerrar
            mantencionesTable[2].lista = mantencionesEnProceso
            mantencionesTable[3].lista = mantencionesSinAsignar
            mantencionesTable[4].lista = mantencionesOrigen
            mantencionesTable.forEach((item) => {
                item.open = false
                const itemGrupo = {}
                item.lista.forEach((report) => {
                    if (!itemGrupo[report.estado]) {
                        itemGrupo[report.estado] = []
                    }
                    itemGrupo[report.estado].push(report)
                })
                item.grupos = itemGrupo
            })

            setMantenciones(mantencionesTable)
        } else {
            const mantencionesTable = [...Mantenciones]
            mantencionesTable[0].number = 0
            mantencionesTable[1].number = 0
            mantencionesTable[2].number = 0
            mantencionesTable[3].number = 0
            mantencionesTable[4].number = 0
            mantencionesTable[0].lista = []
            mantencionesTable[1].lista = []
            mantencionesTable[2].lista = []
            mantencionesTable[3].lista = []
            mantencionesTable[4].lista = []
            setMantenciones(mantencionesTable)
        }
    },[mantencionesTotales])

    const selectList = (item, idButton, tipoCache, index) => {
        setTipo(tipoCache)
        setTypeReportsSelected(item.name)
        localStorage.setItem('buttonSelected', idButton)

        const inspeccionesCache = [...inspecciones]
        inspeccionesCache.forEach((el, i) => {
            const buttonCache = document.getElementById(`button_${i}_inspecciones`)
            if (buttonCache) {
                buttonCache.style.backgroundColor = el.buttonColor
            }
            
            if (tipoCache !== 'inspeccion') {
                el.open = false
            } else {
                console.log(item)
                if (item.open) {
                    item.open = false
                } else {
                    item.open = true
                }
                el = item
            }
        })
        const mantencionesCache = [...mantenciones]
        mantencionesCache.forEach((el, i) => {
            const buttonCache = document.getElementById(`button_${i}_mantenciones`)
            if (buttonCache) {
                buttonCache.style.backgroundColor = el.buttonColor
            }
            if (tipoCache !== 'mantencion') {
                el.open = false
            } else {
                if (item.open) {
                    item.open = false
                } else {
                    item.open = true
                }
                el = item
            }
        })
        document.getElementById(idButton).style.backgroundColor = '#ccc'
        if (item.lista.length > 0) {
            if (item.name === 'Asignar' || item.name === 'En proceso') {
                setListSelected(item.lista.sort(compareReverse))
            } else {
                setListSelected(item.lista.sort(compareNumbers))
            }
            setListSelectedCache(item.lista)
            setVista(false)
        } else {
            setVista(true)
        }
        setInspecciones(inspeccionesCache)
        setMantenciones(mantencionesCache)
    }

    const compareNumbers = (a, b) => {
        return b.idIndex - a.idIndex;
    }

    const compareReverse = (a, b) => {
        return a.idIndex - b.idIndex;
    }

    const reloadData = () => {
        location.reload();
    }

    const initReadList = () => {
        const listGroup = {}
        const totalPagesCache = Math.trunc(((listSelected.length) / rowsPerPage))
        for (let i = 0; i <= totalPagesCache; i++) {
            if (!listGroup[i]) {
                listGroup[i] = []
            }
            listSelected.sort((a, b) => {
                if (a.idIndex > b.idIndex) {
                    return -1
                }
                if (a.idIndex < b.idIndex) {
                    return 1
                }
                return 0
            }).forEach((item, index) => {
                if ((index < (rowsPerPage * (i)) + rowsPerPage) && (index >= (rowsPerPage * (i)))) {
                    listGroup[i].push(item)
                }
            })
        }
        setTotalItems(listSelected.length)
        console.log(listGroup)
        setGrupoReportes(listGroup)
    }

    useEffect(() => {
        if (grupoReportes) {
            setListToShowCache(grupoReportes[page])
            setListToShow(grupoReportes[page])
        }
    }, [grupoReportes, page])

    useEffect(() => {
        console.log(allItemsSelected)
        if (allItemsSelected) {
            const list = [...listToShowCache].map((item) => {
                return {...item, checked: true}
            })
            setListToShow(list)
            setListToShowCache(list)
        } else {
            const list = [...listToShowCache].map((item) => {
                return {...item, checked: false}
            })
            setListToShow(list)
            setListToShowCache(list)   
        }
    }, [allItemsSelected])

    const selectAllItems = (e) => {
        console.log(e.target.checked)
        const list = listToShowCache.map((item) => {
            return {...item, checked: e.target.checked}
        })
        console.log(list)
        setListToShow(list)
        setListToShowCache(list)
    }

    const selectItem = (e, index) => {
        const list = [...listToShowCache]
        list[index].checked = e.target.checked
        setListToShow(list)
        setListToShowCache(list)
    }

    const deleteSelected = async () => {
        const listaSeleccionados = listToShowCache.filter(item => {return item.checked})
        if (listaSeleccionados.length === 0) {
            alert('Debe seleccionar al menos un reporte.')
        } else {
            if (confirm(`Se eliminarán ${listaSeleccionados.length} reportes. Confirme para continuar.`)) {
                await borrarListaReportes(listaSeleccionados.map(el => {
                    return el._id
                }))
                /* const newReports = reports.map((reporte) => {
                    listToShowCache.forEach((r) => {
                        if (r.checked) {
                            reporte.checked = true
                        }
                    })
                    return reporte
                })
                newReports.forEach((repo) => {
                    if (repo.checked) {
                        console.log(repo)
                    }
                }) */
                setListToShow(listToShowCache.filter(item => {return !item.checked}))
                setListToShowCache(listToShowCache.filter(item => {return !item.checked}))
                setPage(0)
                setAllItemsSelected(false)
            }
        }

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
        let lista = []
        for (let i = (page*parseInt(event.target.value, 10)); i < (parseInt(event.target.value, 10) + (page*parseInt(event.target.value, 10))); i++) {
            if (listSelected[i]) {
                lista.push(listSelected[i])
            }
            if (i === ((parseInt(event.target.value, 10) + (page*parseInt(event.target.value, 10))) - 1)) {
                setListToShow(lista)
                setListToShowCache(lista)
            }
        }
    }

    const textoFiltrado = (value) => {
        console.log(value)
        const listaCache = [...listSelectedCache]
        if (value.length > 0) {
            const lista = []
            listaCache.forEach((element, index) => {
                if (index === 0) {
                    console.log(element)
                }
                if (element.idIndex.toString().match((Number(value)).toString()) /* === Number(value) */ || 
                    element.maquina.model.match(value)/*  === value */ || 
                    element.maquina.equ.match(value) /* === value */ || 
                    element.sapId.match(value)/*  === value */) {
                        if (site === 'nada') {
                            lista.push(element)
                        } else {
                            if (admin && (element.idobra === site)) {
                                lista.push(element)
                            }
                        }
                }
                if (index === (listaCache.length - 1)) {
                    setListSelected(lista.sort(compareNumbers))
                    setTotalItems(lista.length)
                }
            })
        } else {
            setListSelected(listSelectedCache.sort(compareNumbers))
            setTotalItems(listSelected.length)
        }
    }

    const seleccionFiltrada = (value) => {
        setSite(value)
    }

    useEffect(() => {
        const listaCache = [...listSelectedCache]
        if (site === 'nada') {
            setListSelected(listSelectedCache)
            setTotalItems(listSelectedCache.length)
        } else {
            const lista = []
            listaCache.forEach((element, index) => {
                if (element.site === site) {
                    lista.push(element)
                }
                if (index === (listaCache.length - 1)) {
                    setListSelected(lista)
                    setTotalItems(lista.length)
                }
            })
        }
    }, [site])

    const downloadFile = async () => {
        downloadReports2()
    }

    const downloadReports2 = async () => {
        const dataCompilada = await descargarDataCompilada()
        console.log(dataCompilada)
        if (dataCompilada.state) {
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(dataCompilada.data["Datos de reporte"]);
            const datosMaterial = XLSX.utils.json_to_sheet(dataCompilada.data["Datos de material"]);
            const datosTareasPendientes = XLSX.utils.json_to_sheet(dataCompilada.data["Datos de tareas pendientes"]);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Datos de reporte");
            XLSX.utils.book_append_sheet(workbook, datosMaterial, "Datos de material");
            XLSX.utils.book_append_sheet(workbook, datosTareasPendientes, "Datos de tareas pendientes");
            XLSX.writeFile(workbook, `${Date.now()}.xlsx`)
        } else {
            alert('Datos no pueden ser descargados en este momento.')
        }
    }

    const openToReportSumary = () => {
        setOpenReportSumary(true)
    }

    const closeToReportSumary = () => {
        setOpenReportSumary(false)
    }

    const closeSeleccionarObra = () => {
        setOpnSeleccionarObra(false)
    }

    const closeKPI = () => {
        setOpnKPI(false)
    }
    const levelToState = (level, usersAssigned, state) => {
        if (level === 0 || !level) {
            if (usersAssigned.length > 0) {
                if (state==='En proceso') {
                    return 'Ejecutando por operador'
                } else if (state==='Asignar') {
                    return 'Reasignar'
                }
            } else {
                return 'Asignar'
            }
        } else if (level===1) {
            return 'Revisión Supervisor'
        } else if (level===2) {
            return 'Revisión J. Maquinaria'
        } else if (level===3) {
            return 'Revisión SAP'
        } else if (level===4) {
            return 'Terminado'
        }
    }

    const filterState = (key, type) => {
        let reportType
        if (type === 'inspeccion') {
            reportType = 'Inspección'
        }

        if (type === 'mantencion') {
            reportType = 'Mantención'
        }
        const reportesCache = [...listSelectedCache]
        const filtroReportes = reportesCache.filter((reporte) => {
            if (reporte.estado === key && reporte.reportType === reportType) {
                return reporte
            }
        })
        setListSelected(filtroReportes)

    }

    return (
        <Box height='100%'>
        {openSeleccionarObra && <SeleccionarObraDialog open={openSeleccionarObra} handleClose={closeSeleccionarObra} />}
        {opnKPI && <ReportsKPIDialog 
            open={opnKPI}
            handleClose={closeKPI}
            reports={reports}
        />
        }
        {openReportSumary && <ReportsResumeDialog
            open={openReportSumary}
            handleClose={closeToReportSumary}
            reports={reports}
        />}
        <Grid className={'root'} container spacing={0}>
            <Grid className={'pageContainer'} item xs={12}>
                <Card elevation={0} className={'pageCard'} >
                    <Grid container alignItems='center' justifyContent='center'>
                        <div className='navigation-bar' /* style={{width: '100%', textAlign: 'left' }} */>
                            <div style={{width: '100%', textAlign: 'left', color: '#333', backgroundColor: '#fff', borderRadius: 20}}>
                            <Toolbar style={{paddingLeft: 0, backgroundColor: '#F9F9F9', borderRadius: 10}}>
                                <IconButton onClick={() => setTimeout(() => {
                                    navigate(-1)
                                }, 500)}>
                                    <ArrowBackIos style={{color: '#333', fontSize: 16}}/> 
                                </IconButton>
                                <Typography variant='h1' style={{marginTop: 0, marginBottom: 0, fontSize: 16, width: '100%'}}>
                                    Ordenes de trabajo
                                </Typography>
                                {
                                    (admin && siteObject) && <p style={{ position: 'absolute',right: 320, textAlign: 'right', maxWidth: 300, borderColor: '#ccc' }}>
                                        Obra seleccionada: <br />{`${siteObject && siteObject.descripcion}`}
                                    </p>
                                }
                                {admin && <IconButton
                                    color={'primary'} 
                                    style={{ marginRight: 5 }}
                                    edge={'end'}
                                    hidden={!hableCreateReport}
                                    onClick={() => setOpnSeleccionarObra(true)}
                                    title='Seleccionar Obra'
                                >
                                    <FontAwesomeIcon icon={faMapMarker}/>
                                </IconButton>}
                                {admin && <IconButton
                                    color={'primary'} 
                                    style={{ marginRight: 5 }}
                                    edge={'end'}
                                    hidden={!hableCreateReport}
                                    onClick={() => deleteSelected()}
                                    title='Borrar OT Seleccionada'
                                >
                                    <FontAwesomeIcon icon={faTrash}/>
                                </IconButton>}
                                {admin && <IconButton
                                    color={'primary'} 
                                    style={{ marginRight: 5 }}
                                    edge={'end'}
                                    hidden={!hableCreateReport}
                                    onClick={() => setOpnKPI(true)}
                                    title='KPI'
                                >
                                    <FontAwesomeIcon icon={faChartBar}/>
                                </IconButton>}
                                {admin && <IconButton
                                    color={'primary'} 
                                    style={{ marginRight: 5 }}
                                    edge={'end'}
                                    hidden={!hableCreateReport}
                                    onClick={downloadFile}
                                    title='Exportar Información de Reportes'
                                >
                                    <FontAwesomeIcon icon={faDownload}/>
                                </IconButton>}
                                <IconButton
                                    color={'primary'} 
                                    style={{ marginRight: 5 }}
                                    edge={'end'}
                                    hidden={!hableCreateReport}
                                    onClick={openToReportSumary}
                                    title='Información de Reportes'
                                >
                                    <FontAwesomeIcon icon={faInfoCircle}/>
                                </IconButton>
                                <IconButton
                                    color={'primary'} 
                                    style={{ marginRight: 5 }}
                                    edge={'end'}
                                    onClick={()=>{navigate('/calendar')}} 
                                    title='Calendario'
                                >
                                    <FontAwesomeIcon icon={faCalendar}/>
                                </IconButton>
                                <IconButton
                                    style={{position: 'relative'}}
                                    color={'primary'} 
                                    edge={'end'}
                                    hidden={!hableCreateReport}
                                    onClick={()=>{navigate('/reports/create-report')}} 
                                    title='Nuevo reporte'
                                >
                                    <FontAwesomeIcon style={{position: 'absolute', zIndex: 100, fontSize: 12, top: 10, right: 10, color: '#F78E63', fontWeight: 600}} icon={faPlus}/>
                                    <FontAwesomeIcon style={{zIndex: 10}} icon={faClipboardList}/>
                                </IconButton>
                            </Toolbar>

                            </div>
                        </div>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={4} lg={'auto'} xl={'auto'}>
                            <div className='menu-card'>
                                <h3>Inspecciones</h3>
                                <div style={{paddingLeft: 10, paddingRight: 10}}>
                                <List dense={true}>
                                {
                                    inspecciones.map((e, i) => {
                                        return(
                                            <div key={i}>
                                                <ListItemButton style={{cursor: 'pointer', display: ((e.name === 'Origen') && isShiftManager) ? 'none' : 'flex', fontSize: 10, borderRadius: 10}} key={`button_${i}_inspecciones`} id={`button_${i}_inspecciones`} 
                                                onClick={()=>selectList(e, `button_${i}_inspecciones`, 'inspeccion', i)}>
                                                    <FontAwesomeIcon icon={faCircle} size='2x' style={{marginRight: 10}} color={e.color}/>
                                                    <ListItemText primary={e.name} />
                                                    <Chip label={e.number} size={'small'} style={{width: 50}} />
                                                    {
                                                        e.grupos && Object.keys(e.grupos).length > 0
                                                        ?
                                                        (e.open ? <ExpandLess style={{marginRight: 10, marginLeft: 10}} /> : <ExpandMore style={{marginRight: 10, marginLeft: 10}} />)
                                                        :
                                                        <div style={{height: 24, width: 24, margin: '0px 10px'}}>

                                                        </div>
                                                    }
                                                </ListItemButton>
                                                <Collapse in={e.open} timeout="auto" unmountOnExit>
                                                    <List dense={true} style={{marginLeft: 10}} component="div" disablePadding>
                                                        {
                                                            e.grupos && Object.keys(e.grupos).map((key, n) => {
                                                                return (
                                                                    <ListItemButton key={n} onClick={() => {filterState(key, 'inspeccion')}} style={{cursor: 'pointer', borderRadius: 10 }} sx={{ pl: 4}}>
                                                                        <ListItemText primary={key} />
                                                                        <Chip label={e.grupos[key].length} size={'small'} style={{width: 40, fontSize: 11}} />
                                                                    </ListItemButton> 
                                                                )
                                                            })
                                                        }
                                                    </List>
                                                </Collapse>
                                            </div>
                                        )
                                    })
                                }
                                </List>
                                </div>
                            </div>
                            <div className='menu-card'>
                                <h3>Mantenciones</h3>
                                <div style={{paddingLeft: 0, paddingRight: 10}}>
                                <List dense={true}>
                                {
                                    mantenciones.map((e, i) => {
                                        return(
                                            <div key={i}>
                                                <ListItemButton 
                                                    style={{cursor: 'pointer', display: ((e.name === 'Origen') && isShiftManager) ? 'none' : 'flex', fontSize: 10, borderRadius: 10}}
                                                    key={`button_${i}_mantenciones`} 
                                                    id={`button_${i}_mantenciones`} 
                                                    onClick={()=>{
                                                        selectList(e, `button_${i}_mantenciones`, 'mantencion', i)
                                                    }}
                                                    >
                                                    <FontAwesomeIcon icon={faCircle} size='2x' style={{marginRight: 10}} color={e.color}/>
                                                    <ListItemText primary={e.name} />
                                                    <Chip label={e.number} size={'small'} style={{width: 50}} />
                                                    {
                                                        e.grupos && Object.keys(e.grupos).length > 0
                                                        ?
                                                        (e.open ? <ExpandLess style={{marginRight: 10, marginLeft: 10}} /> : <ExpandMore style={{marginRight: 10, marginLeft: 10}} />)
                                                        :
                                                        <div style={{height: 24, width: 24, margin: '0px 10px'}}>

                                                        </div>
                                                    }
                                                </ListItemButton>
                                                <Collapse in={e.open} timeout="auto" unmountOnExit>
                                                    <List dense={true} style={{marginLeft: 10}} component="div" disablePadding>
                                                        {
                                                            e.grupos && Object.keys(e.grupos).map((key, n) => {
                                                                return (
                                                                    <ListItemButton key={n} onClick={() => {filterState(key, 'mantencion')}} style={{cursor: 'pointer', borderRadius: 10 }} sx={{ pl: 4}}>
                                                                        <ListItemText primary={key} />
                                                                        <Chip label={e.grupos[key].length} size={'small'} style={{width: 40, fontSize: 11}} />
                                                                    </ListItemButton> 
                                                                )
                                                            })
                                                        }
                                                    </List>
                                                </Collapse>
                                            </div>
                                        )
                                    })
                                }
                                </List>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={'auto'} xl={'auto'}>
                            {
                                vista ? <div>
                                    <img style={{margin: 0, position: 'absolute', top: '50%', left: 'calc(100%/1.53)', msTransform: 'translateY(-50%)', transform: 'translateY(-50%)'}} src="../../assets/icons/Arrow.svg" alt="" />
                                    <div style={{textAlign: 'center', position: 'absolute', top: '55%', left: 'calc(100%/1.6)'}}>
                                        <p>Selecciona una opción <br /> para ver el detalle</p>
                                    </div>
                                </div>
                                :
                                <div style={{height: 'calc(100vh - 240px)', width:'calc(100vw - 350px)', display: 'block', overflowY: 'auto'}}>
                                    <div style={{width: '100%', paddingLeft: 10, paddingRight: 10}}>
                                        <Grid container style={{width: '100%', borderBottomColor: '#ccc', borderBottomStyle: 'solid', borderBottomWidth: 1, paddingBottom: 10}}>
                                            <Grid item xl={'auto'}>
                                                <p style={{ width: 80 }}>Filtros: </p>
                                            </Grid>
                                            <Grid item xl={3}>
                                                <input
                                                    style={{ marginRight: 10, marginTop: 14, marginBottom: 14, width: 300 }}
                                                    placeholder={'Ingrese N° OT, Máquina, Flota o Cód. SAP'}
                                                    onChange={(e) => { textoFiltrado(e.target.value) }}  />
                                            </Grid>
                                            <Grid item sm={'auto'}>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {!vista ? 
                                    <ReportsList 
                                        list={listToShow}
                                        typeReportsSelected={typeReportsSelected}
                                        statusReports={statusReports}
                                        getReports={getReports}
                                        tipo={tipo}
                                        setAllItemsSelected={setAllItemsSelected}
                                        allItemsSelected={allItemsSelected}
                                        selectItem={selectItem}
                                        selectAllItems={selectAllItems}
                                    /> : <></>}
                                    {
                                    (listToShow && listToShow.length > 0) ? <TablePagination
                                        component="div"
                                        color={'primary'}
                                        style={{ position: 'absolute', right: 20, bottom: 10, borderColor: '#ccc', borderWidth: 1, borderStyle: 'solid' }}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}                          
                                        count={totalItems}
                                        page={page}
                                    />
                                        :
                                    <></>
                                }
                                </div>
                            }
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    </Box>

    )
}

export default ReportsPage
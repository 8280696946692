import { createContext, useContext, useEffect, useState } from "react";
import { useReportsContext } from "./Reports.context";
import { InformacionDialog } from "../dialogs";
import { useMachineContext } from "./Machines.context";

export const SystemContext = createContext()

export const SystemProvider = props => {
    const { pautas } = useReportsContext()
    const { machines } = useMachineContext()
    const [ openInfoModal, setOpenInfoModal ] = useState(false)
    const [ datainfo, setDataInfo ] = useState()

    
    useEffect(() => {
        const data = {
            pautasDescargadas: pautas.length,
            maquinasDescargadas: machines.length
        }
        setDataInfo(data)
    }, [pautas])

    const closeModalInfo = () => {
        setOpenInfoModal(false)
    }
   
    const provider = {
        setOpenInfoModal
    }

    return (
        <>
        <SystemContext.Provider value={provider} {...props} />
        {
            openInfoModal &&
            <InformacionDialog 
                open={openInfoModal}
                handleClose={closeModalInfo}
                data={datainfo}
            />
        }
        </>
    )
}

export const useSystemContext = () => useContext(SystemContext)
import { Close } from '@mui/icons-material';
import { forwardRef } from 'react';
import React from 'react';
import { Box, Dialog, Fab, ListItem, Slide, Switch, Grid } from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UserListDataDialog = ({open, userModal, handleChange, checked, closeModal}) => {

    return(
        <Dialog
            open={open}
            TransitionComponent={Transition}
            adaptiveHeight={true}
            >
            <Box style={{padding: 20}}>
                <div style={{textAlign: 'left', width: '100%'}}>
                    <h2>Datos del Usuario</h2>
                </div>
                <Grid container>
                    <Grid item>
                        <div style={{padding: 5}}>
                            <p><strong>Nombre y Apellido</strong></p>
                            <p> {userModal.name} {userModal.lastName} </p>
                            <br />
                            <p><strong>Rol del usuario</strong></p>
                            <p> {userModal.roleTranslated} </p>
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{padding: 5}}>
                            <p><strong>Email</strong></p>
                            <p> {userModal.email}</p>
                            <br />
                            <p><strong>RUN</strong></p>
                            <p> {userModal.rut} </p>
                        </div>
                    </Grid>
                    <Grid item>
                        <ListItem>
                            <Switch onChange={handleChange} checked={checked} ></Switch> 
                            {
                                checked && <p>Usuario activo</p>
                            }
                            {
                                !checked && <p>Usuario inactivo</p>
                            }
                        </ListItem>
                    </Grid>
                </Grid>
                <Fab onClick={() => closeModal(userModal)} style={{position: 'absolute', right: 10, top: 10, boxShadow: 'none', backgroundColor: 'transparent'}}>
                    <Close style={{color: '#ccc'}} />
                </Fab>
            </Box>  
        </Dialog>
    )
}

export default UserListDataDialog
import React, { useState, useContext, useEffect } from 'react'
import { Box, Card, Grid, Toolbar, IconButton, Button, useMediaQuery, useTheme, CircularProgress, Hidden, Divider } from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'
import { date, useStylesTheme } from '../../config'
import { useNavigate } from 'react-router-dom'
/* import { machinesDatabase, reportsDatabase } from '../../indexedDB' */
import { AuthContext, ExecutionReportContext, useReportsContext, useSitesContext } from '../../context'
import { FormControl, InputLabel, ListItemButton, MenuItem, Select } from '@mui/material'
import SeleccionarObraDialog from '../../dialogs/SeleccionarObraDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faMapMarker, faPen, faReply, faRepublican } from '@fortawesome/free-solid-svg-icons'
import { usersRoutes } from '../../routes'

const ActivitiesPage = () => {
    const navigate = useNavigate();
    const {isOperator, isSapExecutive, isShiftManager, isChiefMachinery, admin, userData} = useContext(AuthContext)
    const {setReport, setExecutionReport, setRecovery} = useContext(ExecutionReportContext)
    const {priorityAssignments, normalAssignments, siteSelected} = useReportsContext()
    const {sites} = useSitesContext()
    const [showList, setShowList] = useState(true)
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const [asignaciones, setAsignaciones] = useState('only')
    const [listadoReportes, setListadoReportes] = useState([])
    const [listadoReportesCache, setListadoReportesCache] = useState([])
    const [openSeleccionarObra, setOpnSeleccionarObra] = useState(false)
    const [loading, setLoading] = useState(false)
    const [order, setOrder] = useState(false)
    const [siteObject, setSiteObject] = useState()

    useEffect(() => {
        setTimeout(() => {
            console.log(priorityAssignments.length, normalAssignments.length)
            priorityAssignments.forEach(async (report) => {
                if (report.historialAsignaciones.length > 0) {
                    const asignadoPor = await usersRoutes.getUser(report.historialAsignaciones[report.historialAsignaciones.length-1].asignadoPor)
                    const asignadoA = await usersRoutes.getUser(report.historialAsignaciones[report.historialAsignaciones.length-1].operario)
                    report.asignadoPor = asignadoPor.data
                    report.asignadoA = asignadoA.data
                }
            })
            setListadoReportes(priorityAssignments)
            setListadoReportesCache(priorityAssignments)
            setLoading(false)
        },[500])
    },[priorityAssignments, normalAssignments])

    useEffect(() => {
        if (sites.length > 0 && siteSelected) {
            console.log(sites)
            const siteFiltered = sites.filter(site => {if (site.idobra === siteSelected) return site})[0]
            setSiteObject(siteFiltered)
        }
    },[siteSelected])

    const goToDetail = (element, recovery) => {
        if (recovery) {
            if (confirm('Modo recuperación.\nUsará datos que se encuentran en su dispositivo.\n¿Desea continuar?')) {
                /* setExecutionReport(element.tieneEjecucionEnDispositivo) */
                setRecovery(true)
                navigate(`/assignment/${element.idIndex}`)
            }
        } else {
            navigate(`/assignment/${element.idIndex}`)
            setRecovery(false)

        }
        
    }

    const compareBy = (value) => {
        console.log(value)
        if (order) {
            setOrder(false)
        } else {
            setOrder(true)
        }
        const listadoReportesCache2 = [...listadoReportesCache]
        if (value === 'ot') {
            setListadoReportes(listadoReportesCache2.sort(compareByOt))
        } else if (value === 'dateInit') {
            setListadoReportes(listadoReportesCache2.sort(compareInicio))
        } else if (value === 'datePrev') {
            setListadoReportes(listadoReportesCache2.sort(compareInicioProgramado))
        } else if (value === 'endPrev') {
            setListadoReportes(listadoReportesCache2.sort(compareTerminoProgramado))
        } else if (value === 'endReport') {
            setListadoReportes(listadoReportesCache2.sort(compareTermino))
        } else if (value === 'guia') {
            setListadoReportes(listadoReportesCache2.sort(compareByGuia))
        } else if (value === 'sap') {
            setListadoReportes(listadoReportesCache2.sort(compareBySAP))
        } else if (value === 'flota') {
            setListadoReportes(listadoReportesCache2.sort(compareFlota))
        }
    }

    const compareByOt = (a, b) => {
        if (order) {
            return a.idIndex - b.idIndex
        } else {
            return b.idIndex - a.idIndex
        }
    }

    const compareByGuia = (a, b) => {
        if (order) {
            if(a.guide < b.guide) { return -1; }
            if(a.guide > b.guide) { return 1; }
            return 0
        } else {
            if(b.guide < a.guide) { return -1; }
            if(b.guide > a.guide) { return 1; }
            return 0
        }
    }

    const compareBySAP = (a, b) => {
        if (order) {
            return a.sapId - b.sapId
        } else {
            return b.sapId - a.sapId
        }
    }

    const compareFlota = (a, b) => {
        if (`${a.maquina && a.maquina.type} ${a.maquina && a.maquina.model}` < `${b.maquina && b.maquina.type} ${b.maquina && b.maquina.model}`) {
            return -1
        }
        if (`${a.maquina && a.maquina.type} ${a.maquina && a.maquina.model}` > `${b.maquina && b.maquina.type} ${b.maquina && b.maquina.model}`) {
            return 1
        }
        return 0
        
    }

    const compareInicio = (a, b) => {
        if (order) {
            return new Date(a.dateInit) - new Date(b.dateInit)
        } else {
            return new Date(b.dateInit) - new Date(a.dateInit)
        }
    }

    const compareInicioProgramado = (a, b) => {
        if (order) {
            return new Date(a.datePrev) - new Date(b.datePrev)
        } else {
            return new Date(b.datePrev) - new Date(a.datePrev)
        }
    }

    const compareTerminoProgramado = (a, b) => {
        if (order) {
            return new Date(a.endPrev) - new Date(b.endPrev)
        } else {
            return new Date(b.endPrev) - new Date(a.endPrev)
        }
    }

    const compareTermino = (a, b) => {
        if (order) {
            return new Date(a.endReport) - new Date(b.endReport)
        } else {
            return new Date(b.endReport) - new Date(a.endReport)
        }
    }

    useEffect(() => {
        if (asignaciones === 'only') {
            setListadoReportes(priorityAssignments)
            setListadoReportesCache(priorityAssignments)
        } else {
            /* setListadoReportes(normalAssignments)
            setListadoReportesCache(normalAssignments) */
        }
    },[asignaciones])

    const closeSeleccionarObra = (value) => {
        if (value === 'Selected') {
            setLoading(true)
            setListadoReportes([])
        }
        setOpnSeleccionarObra(false)
    }

    return (
        <Box height='100%'>
            {
            openSeleccionarObra && <SeleccionarObraDialog open={openSeleccionarObra} handleClose={closeSeleccionarObra} />
            }
            <Grid className={'root'} container spacing={0}>
                <Grid className={'pageContainer'} item xs={12}>
                    <Card elevation={0} className={'pageCard'}>
                        <Grid container alignItems='center' justifyContent='center'>
                            <div style={{width: '100%', textAlign: 'left', padding: 10 }}>
                                <div style={{width: '100%', textAlign: 'left', color: '#333', backgroundColor: '#fff', borderRadius: 20 }}>
                                    <Toolbar style={{paddingLeft: 0, backgroundColor: '#F9F9F9', borderRadius: 10, width:'100%'}}>
                                        <IconButton onClick={() => setTimeout(() => {
                                            navigate(-1)
                                        }, 500)}> 
                                            <ArrowBackIos style={{color: '#333', fontSize: 16}}/> 
                                        </IconButton> 
                                        <h1 style={{marginTop: 0, marginBottom: 0, fontSize: 16}}>
                                            Actividades Asignadas
                                        </h1>
                                        {
                                            (admin && siteObject) && <p style={{ position: 'absolute',right: 70, textAlign: 'right', maxWidth: 300, borderColor: '#ccc' }}>
                                                Obra seleccionada: <br />{`${siteObject && siteObject.descripcion}`}
                                            </p>
                                        }
                                        {admin && <IconButton
                                            color={'primary'} 
                                            style={{ position: 'absolute',right: 5, marginRight: 5 }}
                                            edge={'end'}
                                            /* hidden={!hableCreateReport} */
                                            onClick={() => setOpnSeleccionarObra(true)}
                                            title='Seleccionar Obra'
                                        >
                                            <FontAwesomeIcon icon={faMapMarker}/>
                                        </IconButton>}
                                        {/* !admin && <FormControl fullWidth style={{ position: 'absolute',right: 5, width: 180, borderColor: '#ccc' }}>
                                            <InputLabel id="demo-simple-select-label" color={'error'}>Lista</InputLabel>
                                            <Select
                                                color={'error'}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={asignaciones}
                                                label="Age"
                                                onChange={(e) => {setAsignaciones(e.target.value)}}
                                            >
                                                <MenuItem value={'only'}>Mis Asignaciones</MenuItem>
                                                <MenuItem value={'all'}>Otras</MenuItem>
                                            </Select>
                                        </FormControl> */}
                                    </Toolbar>
                                </div>
                            </div>
                            <div style={{width: '100%', padding: 10}}>
                                <Hidden mdDown>
                                <Grid container direction='column'>
                                    <div style={
                                        {
                                            width: '100%',
                                            borderRadius: 10,
                                            borderStyle: 'solid',
                                            borderWidth: 1,
                                            borderColor: '#CCC',
                                            backgroundColor: '#CCC',
                                            marginBottom: 10
                                        }
                                    }>
                                        <Grid container>
                                            <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={1} xs={1}>
                                                <ListItemButton onClick={() => {compareBy('ot')}} style={{width: '4vw', textAlign: 'center', padding: 0, margin: '12px 0px'}}>
                                                    <p style={{width: '4vw', fontSize: 12, fontWeight: 'bold'}}>N° OT</p>
                                                </ListItemButton>
                                            </Grid>
                                            <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={1} xs={1}>
                                                <ListItemButton onClick={() => {compareBy('guia')}} style={{width: '5vw', textAlign: 'center', padding: 0, margin: '12px 0px'}}>
                                                    <p style={{width: '5vw', fontSize: 12, fontWeight: 'bold'}}>Guía</p>
                                                </ListItemButton>
                                            </Grid>
                                            <Grid item xl={'auto'} lg={'auto'} md={'auto'}>
                                                <ListItemButton onClick={() => {compareBy('sap')}} style={{width: '6vw', textAlign: 'center', padding: 0, margin: '12px 0px'}}>
                                                    <p style={{width: '6vw', fontSize: 12, fontWeight: 'bold'}}>SAP ID</p>
                                                </ListItemButton>
                                            </Grid>
                                            <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={'auto'} xs={'auto'}>
                                                <ListItemButton onClick={() => {compareBy('datePrev')}} style={{width: '8vw', padding: 0, margin: '12px 0px'}}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', width: '8vw'}}>Inicio <br /> programado</p>
                                                </ListItemButton>
                                            </Grid>
                                            <Grid item l={'auto'} lg={'auto'} md={'auto'} sm={'auto'} xs={'auto'}>
                                                <ListItemButton onClick={() => {compareBy('dateInit')}} style={{width: '8vw', padding: 0, margin: '12px 0px'}}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', width: '8vw'}}>Inicio <br /> ejecución</p>
                                                </ListItemButton>
                                            </Grid>
                                            <Hidden mdDown>
                                                <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={'auto'} xs={'auto'}>
                                                    <ListItemButton onClick={() => {compareBy('endPrev')}} style={{width: '8vw', padding: 0, margin: '12px 0px'}}>
                                                    <p style={{fontSize: 12, fontWeight: 'bold', width: '8vw'}}>Término <br /> programado</p>
                                                    </ListItemButton>
                                                </Grid>
                                                <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={'auto'} xs={'auto'}>
                                                    <ListItemButton onClick={() => {compareBy('endReport')}} style={{width: '8vw', padding: 0, margin: '12px 0px'}}>
                                                    <p style={{fontSize: 12, fontWeight: 'bold', width: '8vw'}}>Término <br /> ejecución</p>
                                                    </ListItemButton>
                                                </Grid>
                                            </Hidden>
                                            <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={'auto'} xs={'auto'}>
                                                <ListItemButton onClick={() => {compareBy('flota')}} style={{width: '9vw', textAlign: 'center', padding: 0, margin: '12px 0px', opacity: 1}}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', textAlign: 'left', width: '9vw'}}>Flota</p>
                                                </ListItemButton>
                                            </Grid>
                                            <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={'auto'} xs={'auto'}>
                                                <ListItemButton disabled style={{width: '3vw', textAlign: 'center', padding: 0, margin: '12px 0px', opacity: 1}}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', textAlign: 'left', width: '3vw'}}> Progreso </p>
                                                </ListItemButton>
                                            </Grid>
                                            <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={'auto'} xs={'auto'}>
                                                <ListItemButton disabled style={{width: '8vw', textAlign: 'center', padding: 0, margin: '12px 0px', opacity: 1}}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', textAlign: 'center', width: '8vw'}}>Nro Máquina</p>
                                                    </ListItemButton>
                                            </Grid>
                                            <Hidden mdDown>
                                                <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={'auto'} xs={'auto'}>
                                                    <ListItemButton disabled style={{width: '8vw', textAlign: 'center', padding: 0, margin: '12px 0px', opacity: 1}}>
                                                    <p style={{fontSize: 12, fontWeight: 'bold', textAlign: 'center', width: '8vw'}}>Asignado a</p>
                                                    </ListItemButton>
                                                </Grid>
                                                <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={'auto'} xs={'auto'}>
                                                    <ListItemButton disabled style={{width: '8vw', textAlign: 'center', padding: 0, margin: '12px 0px', opacity: 1}}>
                                                    <p style={{fontSize: 12, fontWeight: 'bold', textAlign: 'center', width: '8vw'}}>Asignado por</p>
                                                        </ListItemButton>
                                                </Grid>
                                            </Hidden>
                                            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                                <div style={{textAlign: 'center', width: '100%'}}>
                                                    <ListItemButton disabled style={{ textAlign: 'center', width: '100%', padding: 0, margin: '12px 0px', color: 'black', opacity: 1}}>
                                                        <p style={{fontSize: 12, fontWeight: 'bold', width: '100%', marginRight: 0, color: 'black'}}>Acción</p>
                                                    </ListItemButton>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                </Hidden>
                                {showList && <Grid container style={
                                    {
                                        overflowY: 'auto',
                                        maxHeight: 'calc(100vh - 295px)'
                                    }
                                }
                                >
                                    {
                                        loading && <div style={{width: '100%', textAlign: 'center', minHeight: 100}}>
                                            <CircularProgress />
                                        </div>
                                    }
                                    {
                                        listadoReportes.map((element, i) => {
                                            return(
                                                <div key={i} style={
                                                    {
                                                        width: '100%', 
                                                        borderRadius: 10, 
                                                        borderStyle: 'solid', 
                                                        borderWidth: 1, 
                                                        borderColor: element.readyToSend ? '#be2e26' : '#CCC',
                                                        marginBottom: 10,
                                                        backgroundColor: element.endReport ? '#f2f2f2' : '#fff'
                                                    }
                                                }>
                                                    <Grid container>
                                                        <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={3} xs={3}>
                                                            <Hidden mdUp>
                                                                <div style={{padding: '0px 10px'}}>
                                                                <p style={{fontSize: 11}}>OT: {element.idIndex}</p>
                                                                </div>
                                                            </Hidden>
                                                            <Hidden mdDown>
                                                                <div style={{width: '4vw', textAlign: 'center'}}>
                                                                    <p style={{fontSize: 11}}>{element.idIndex}</p>
                                                                </div>
                                                            </Hidden>
                                                        </Grid>
                                                        <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={4} xs={4}>
                                                            <Hidden mdUp>
                                                                <div style={{padding: '0px 10px'}}>
                                                                <p style={{fontSize: 11}}>Guía: {element._guide}</p>
                                                                </div>
                                                            </Hidden>
                                                            <Hidden mdDown>
                                                                <div style={{width: '5vw', textAlign: 'center'}}>
                                                                <p style={{fontSize: 11}}>{element._guide}</p>
                                                                </div>
                                                            </Hidden>
                                                        </Grid>
                                                        <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={5} xs={5}>
                                                            <Hidden mdUp>
                                                                <div style={{padding: '0px 10px'}}>
                                                                <p style={{fontSize: 11}}>SAP: {element.sapId}</p>
                                                                </div>
                                                            </Hidden>
                                                            <Hidden mdDown>
                                                                <div style={{textAlign: 'center', width: '6vw'}}>
                                                                    <p style={{fontSize: 11}}>{element.sapId}</p>
                                                                </div>
                                                            </Hidden>
                                                        </Grid>
                                                        <Hidden mdUp>
                                                            <Divider />
                                                        </Hidden>
                                                        <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={6} xs={6}>
                                                            <Hidden mdUp>
                                                                <div style={{padding: '0px 10px'}}>
                                                                <p style={{fontSize: 11}}>Fecha prevista:<br /> {date(element.datePrev)}</p>
                                                                </div>
                                                            </Hidden>
                                                            <Hidden mdDown>
                                                                <div style={{width: '100%', textAlign: 'left'}}>
                                                                    <p style={{fontSize: 11, width: '8vw'}}>{date(element.datePrev)}</p>
                                                                </div>
                                                            </Hidden>
                                                        </Grid>
                                                        <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={6} xs={6}>
                                                            <Hidden mdUp>
                                                                <div style={{padding: '0px 10px'}}>
                                                                <p style={{fontSize: 11}}>Fecha inicio: <br />{date(element.dateInit)}</p>
                                                                </div>
                                                            </Hidden>
                                                            <Hidden mdDown>
                                                                <div style={{width: '100%', textAlign: 'left'}}>
                                                                    <p style={{fontSize: 11, width: '8vw'}}>{date(element.dateInit)}</p>
                                                                </div>
                                                            </Hidden>
                                                        </Grid>
                                                        <Hidden mdDown>
                                                            <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={'auto'} xs={'auto'}>
                                                                <p style={{fontSize: 11, width: '8vw'}}> {date(element.endPrev)} </p>
                                                            </Grid>
                                                            <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={'auto'} xs={'auto'}>
                                                                <div style={{width: '100%', textAlign: 'left'}}>
                                                                    <p style={{fontSize: 11, width: '8vw'}}>{date(element.endReport)}</p>
                                                                </div>
                                                            </Grid>
                                                        </Hidden>
                                                        <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={12} xs={12}>
                                                            <Hidden mdUp>
                                                                <div style={{padding: '0px 10px'}}>
                                                                <p style={{fontSize: 11, textAlign: 'left'}}>Flota: {element.maquina && element.maquina.type} {element.maquina && element.maquina.model} </p>
                                                                </div>
                                                            </Hidden>
                                                            <Hidden mdDown>
                                                                <div style={{width: '100%', textAlign: 'left'}}>
                                                                <p style={{fontSize: 11, textAlign: 'left', width: '9vw'}}> {element.maquina && element.maquina.type} {element.maquina && element.maquina.model} </p>
                                                                </div>
                                                            </Hidden>
                                                        </Grid>
                                                        <Hidden mdUp>
                                                            <Divider />
                                                        </Hidden>
                                                        <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={6} xs={6}>
                                                            <Hidden mdUp>
                                                                <div style={{padding: '0px 10px'}}>
                                                                <p style={{fontSize: 11, textAlign: 'left'}}>Progreso: {element.progress ? `${element.progress}%` : 'S/I'} </p>
                                                                </div>
                                                            </Hidden>
                                                            <Hidden mdDown>
                                                                <div style={{width: '100%', textAlign: 'left'}}>
                                                                <p style={{fontSize: 11, textAlign: 'left', width: '3vw'}}> {element.progress ? `${element.progress}%` : 'S/I'} </p>
                                                                </div>
                                                            </Hidden>
                                                        </Grid>
                                                        <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={6} xs={6}>
                                                            <Hidden mdUp>
                                                                <div style={{padding: '0px 10px'}}>
                                                                <p style={{fontSize: 11, textAlign: 'center'}}>Máquina N°: {element.maquina && element.maquina.equ} </p>
                                                                </div>
                                                            </Hidden>
                                                            <Hidden mdDown>
                                                                <div style={{width: '100%', textAlign: 'left'}}>
                                                                <p style={{fontSize: 11, textAlign: 'center', width: '8vw'}}> N°: {element.maquina && element.maquina.equ} </p>
                                                                </div>
                                                            </Hidden>
                                                        </Grid>
                                                        <Hidden mdDown>
                                                            <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={'auto'} xs={'auto'}>
                                                                    <p style={{fontSize: 11, textAlign: 'center', width: '8vw'}}>
                                                                        {element.asignadoA ? `${element.asignadoA.name} ${element.asignadoA.lastName}` : 'Sin información'}
                                                                    </p>
                                                            </Grid>
                                                            <Grid item xl={'auto'} lg={'auto'} md={'auto'} sm={'auto'} xs={'auto'}>
                                                                <p style={{fontSize: 11, textAlign: 'center', width: '8vw'}}>
                                                                    {element.asignadoPor ? `${element.asignadoPor.name} ${element.asignadoPor.lastName}` : 'Sin información'}
                                                                </p>
                                                            </Grid>
                                                        </Hidden>
                                                        <Hidden mdUp>
                                                            <Divider />
                                                        </Hidden>
                                                        <Grid item xl={1} lg={1} md={1} sm={12} xs={12}>
                                                            <Hidden mdUp>
                                                                <div style={{width: '100%', textAlign: 'center', paddingRight: 0}}>
                                                                    <IconButton onClick={()=>{goToDetail(element, false)}} color='primary' >
                                                                        <p style={{margin:0}}>{
                                                                            (!isOperator && element.level && (element.level > 0))
                                                                            ? 
                                                                            'Ver'
                                                                            : 
                                                                            ((isOperator && ((element.usersAssigned && (element.usersAssigned[0] && (element.usersAssigned[0]._id === userData._id))) )) ? (element.readyToSend ? 'Listo a enviar' : <FontAwesomeIcon icon={faPen} />) :  <FontAwesomeIcon icon={faEye} />)
                                                                        }</p>
                                                                    </IconButton>
                                                                    {
                                                                        element.tieneEjecucionEnDispositivo && <IconButton onClick={()=>{goToDetail(element, true)}} color='primary'>
                                                                                <FontAwesomeIcon icon={faReply} />
                                                                        </IconButton>
                                                                    }
                                                                </div>
                                                            </Hidden>
                                                            <Hidden mdDown>
                                                                <div style={{width: '100%', textAlign: 'center', paddingRight: 0}}>
                                                                    <IconButton onClick={()=>{goToDetail(element, false)}} color='primary'>
                                                                        <p style={{margin:0}}>{
                                                                            (!isOperator && element.level && (element.level > 0))
                                                                            ? 
                                                                            'Ver'
                                                                            : 
                                                                            ((isOperator && ((element.usersAssigned && (element.usersAssigned[0] && (element.usersAssigned[0]._id === userData._id))) )) ? (element.readyToSend ? 'Listo a enviar' :  <FontAwesomeIcon icon={faPen} />) :  <FontAwesomeIcon icon={faEye} />)
                                                                        }</p>
                                                                    </IconButton>                                                                    
                                                                    {
                                                                        element.tieneEjecucionEnDispositivo && 
                                                                        <IconButton onClick={()=>{goToDetail(element, true)}} color='primary'>
                                                                             <FontAwesomeIcon icon={faReply} />
                                                                        </IconButton>
                                                                    }
                                                                </div>
                                                            </Hidden>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )
                                        })
                                    }
                                </Grid>}
                                <Grid>
                                    {
                                        !showList && 
                                        <div style={{ width: '100%', textAlign: 'center', height: 100 }}>
                                            <CircularProgress size={50} />
                                        </div>
                                    }
                                </Grid>
                            </div>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ActivitiesPage
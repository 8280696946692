import axios from 'axios'

export default {
    createReport: (report) => {
        const requestBody = {
            report,
        }
        return axios.post('/reports/createReport', requestBody);
    },
    editReport: (report) => {
        const requestBody = {
            report,
        }
        return axios.post('/reports/editReport', requestBody);
    },
    editReportCounter: (_id, ejecuciones) => {
        return axios.post('/reports/editReportCounter', {_id, ejecuciones})
    },
    editReportById: (report) => {
        const requestBody = {
            report,
        }
        return axios.post('/reports/editReportById', requestBody);
    },
    /* editReportFromAudit: (report, generateLink) => {
        const requestBody = {
            report,
            generateLink
        }
        return axios.post('/reports/editReportFromAudit', requestBody);
    }, */
    deleteReport: (id) => {
        const requestBody = {
            id,
        }
        return axios.post('/reports/deleteReport', requestBody);
    },
    getReportById: (_id) => {
        const requestBody = {
            _id
        }
        return axios.post('/reports/getReportById', requestBody);
    },
    getAllReports: (setReportsProgress, serverUrl) => {
        const request = new Request(`${serverUrl}/reports/getAllReports`, {
            method: 'GET',
            headers:{
                "Content-Type": "application/json"
            }
        });
        return fetch(request).then(progressHelper(setReportsProgress))
        /* console.log('Descargando reportes...')
        return axios.get('/reports/getAllReports', {
            onDownloadProgress: (progressEvent) => {
                console.log(progressEvent)
                const total = parseFloat(progressEvent.loaded)
                const current = progressEvent.currentTarget.response.length

                let percentCompleted = Math.floor(current / total * 100)
                console.log('completed: ', percentCompleted)
                setReportsProgress(total)
                if(percentCompleted===100) {
                    setFinishReportsProgress(true)
                }
            }
        }) */
    },
    getAllReportsbySite: (site, setReportsProgress, serverUrl) => {
        const request = new Request(`${serverUrl}/reports/getAllReportsbySite`, {
            method: 'POST',
            body: JSON.stringify({site: site}),
            headers:{
                "Content-Type": "application/json"
            }
        });
        return fetch(request).then(progressHelper(setReportsProgress))
    },
    getReportByIndex: (indexNumber) => {
        const requestBody = {
            indexNumber,
        }
        return axios.post('/reports/getReportByIndex', requestBody);
    },
    getReportByGuide: (guideNumber) => {
        const requestBody = {
            guideNumber,
        }
        return axios.post('/reports/getReportByGuide', requestBody);
    },
    getReportByType: (type) => {
        const requestBody = {
            type
            
        }
        return axios.post('/reports/getReportByType', requestBody);
    },
    getReportByState: (state, reportType) => {
        const requestBody = {
            state,
            reportType
        }
        return axios.post('/reports/getReportByState', requestBody);
    },
    getReportByStateAndSite: (state, reportType, site) => {
        const requestBody = {
            state,
            reportType,
            site
        }
        return axios.post('/reports/getReportByStateAndSite', requestBody);
    },
    getReportsByUser: (userId) => {
        const requestBody = {
            userId
        }
        return axios.post('/reports/getReportsByUser', requestBody);
    },
    findMyAssignations: (userId, site, setReportsProgress, serverUrl) => {
        /* const requestBody = {
            userId,
            site
        };
        return axios.post('/reports/findMyAssignations', requestBody); */
        const request = new Request(`${serverUrl}/reports/findMyAssignations`, {
            method: 'POST',
            body: JSON.stringify({site: site, userId: userId}),
            headers:{
                "Content-Type": "application/json"
            }
        });
        return fetch(request).then(progressHelper(setReportsProgress))

    },
    getReportByEquid: (equid) => {
        const requestBody = {
            equid
        };
        return axios.post('/reports/getReportByEquid', requestBody);
    },
    getReportByIdpm: (idpm) => {
        const requestBody = {
            idpm
        };
        return axios.post('/reports/getReportByIdpm', requestBody);
    },
    getReportsByDateRange: (dateInit, dateEnd, reportType) => {
        const requestBody = {
            dateInit,
            dateEnd,
            reportType
        };
        return axios.post('/reports/getReportsByDateRange', requestBody);
    },
    getReportsByDateRangeAndSite: (dateInit, dateEnd, reportType, site) => {
        const requestBody = {
            dateInit,
            dateEnd,
            reportType,
            site
        };
        return axios.post('/reports/getReportsByDateRangeAndSite', requestBody);
    },
    getTotalReportsToIndex: () => {
        return axios.get('/reports/getTotalReportsToIndex')
    },
    getReporstWithBacklogsByMachine: (equid) => {
        return axios.get(`/reports/getReporstWithBacklogsByMachine/${equid}`)
    },
    findBacklogsInReport: (reportId) => {
        return axios.get(`/reports/findBacklogsInReport/${reportId}`)
    }
    

}

const progressHelper = (onProgress) => {
    return (response) => {
      if (!response.body) return response
  
      let loaded = 0
      const contentLength = response.headers.get('content-length')
      console.log(contentLength)
      const total = !contentLength ? -1 : parseInt(contentLength, 10)
  
      return new Response(
        new ReadableStream({
          start(controller) {
            const reader = response.body.getReader()
            return read()
  
            function read() {
              return reader.read()
                .then(({ done, value }) => {
                  if (done) return void controller.close()
                  loaded += value.byteLength
                  onProgress({ loaded, total })
                  controller.enqueue(value)
                  return read()
                })
                .catch(error => {
                  console.error(error)
                  controller.error(error)
                })
            }
          }
        })
      )
    }
  }
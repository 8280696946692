import { Button, Dialog, Grid, IconButton, Slide, TextField, TextareaAutosize } from "@mui/material"
import { ArrowForward, Close } from "@mui/icons-material"
import { forwardRef } from "react";
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const InputTextDialog = ({handleClickOpen, open, handleClose, message, inputMessage, saveMessage}) => {
    setTimeout(() => {
        document.getElementById('textField').focus()
    }, 100);
    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                Open full-screen dialog
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <IconButton
                    style={{position: 'absolute', top: 10, right: 10, backgroundColor: '#fff'}}
                    onClick={handleClose}
                    >
                    <Close />
                </IconButton>
                {/* <IconButton
                    style={{position: 'absolute', top: 20, right: 20, backgroundColor: '#fff'}}
                    onClick={() => {saveMessage(); handleClose()}}
                    >
                    Enviar <ArrowForward />
                </IconButton> */}
                <div style={{padding: '50px 10px', textAlign: 'center'}}>
                    <Grid container>
                        <Grid item xs={12} style={{marginBottom: 10}}>
                            <TextareaAutosize minRows={5} style={{width: '100%'}} id="textField" value={message} onChange={(e) => {inputMessage(e.target.value)}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant={'contained'} style={{width: '100%'}} onClick={() => {saveMessage(); handleClose()}}>
                                Enviar
                            </Button>
                        </Grid>
                    </Grid>
                    
                    
                </div>
            </Dialog>
        </div>
    )
}

export default InputTextDialog
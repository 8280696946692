import { useState, useEffect } from 'react';
import { 
    Fab,
    TextareaAutosize,
    Grid,
    Button,
    Dialog,
    Input,

} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useAuth } from '../context';

const ReportCommitDialog = ({open, report, closeModal, isTermJornada, terminarjornada, messageType}) => {
    const {userData} = useAuth()
    const [ message, setMessage ] = useState('')
    const [operadores, setOperadores] = useState(0)
    const [pregunta, setPregunta] = useState(false)
    const [level, setLevel] = useState(0)
    const sendMessage = () => {
        if(message.length > 1) {
            const navigateType = isTermJornada ? 'reject-for-end-day' : (messageType==='rejectReport' ? 'reject-to-previous-level' : 'sending-to-next-level')
            console.log(navigateType)
            report.history.push({
                operadores: (level < 1) ? operadores : null,
                id: Date.now(),
                userSendingData: userData._id,
                type: navigateType,
                message: message
            })
            if (isTermJornada) {
                terminarjornada()
            } else {
                closeModal(true)
            }
        }else{
            alert('Por favor deje un comentario a la actividad.')
        }
        
    }

    useEffect(() => {
        if (report) {
            if (report.level) {
                setLevel(report.level)
            }
        }
    }, [report])

    const removeMessage = () => {
        setMessage('');
    }

    const closeModalActivate = () => {
        closeModal(false);
    }
    
    
    return(
        <Dialog
            open={open}
        >
            <div style={{padding: 30, width: 600}}>
                <Grid>
                    {
                        isTermJornada ? 
                        <h3>
                            Terminar Jornada.
                        </h3>
                        :
                        <h3>
                            Enviar OT a revisión.
                        </h3>
                    }
                </Grid>
                <Grid container style={{padding: 10}}>
                    <div style={{width: '100%', marginBottom: 30, marginTop: 30}}>
                        <TextareaAutosize 
                        aria-label="empty textarea"
                        placeholder="Deje su comentario"
                        minRows={10}
                        maxRows={15}
                        style={{ width: '100%', fontSize: 18, fontFamily: 'Arial, Helvetica, sans-serif', marginBottom: 30 }}
                        value={message}
                        onChange={(e)=>{setMessage(e.target.value)}}
                        />
                        {( level < 1) && <Grid container>
                            <Grid item>
                                <Input onChange={(e) => setOperadores(e.target.value)} type={'number'} placeholder='N° Operadores'/>
                            </Grid>
                            <Grid item>
                                <button style={{borderRadius: '50%', borderWidth: 0.5, marginLeft: 10}} onClick={() => {setPregunta(pregunta ? false : true)}}>
                                    ?
                                </button>
                            </Grid>
                            <Grid item>
                                {
                                    pregunta && 
                                    <p style={{fontSize: 9, marginLeft: 10}}>
                                        N° de operadores que trabajaron con usted.
                                    </p>
                                }
                            </Grid>
                        </Grid>}
                    </div>
                    <Button variant="contained" color={'primary'} style={{ borderRadius: 50 }} onClick={()=>{sendMessage()}}>
                        Enviar Mensaje
                    </Button>
                </Grid>
                <Fab onClick={()=>closeModalActivate()} style={{position: 'absolute', right: 10, top: 10, boxShadow: 'none', backgroundColor: 'transparent'}}>
                    <Close style={{color: '#ccc'}} />
                </Fab>
            </div>
        </Dialog>
    )
}

export default ReportCommitDialog
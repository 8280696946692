import { useState, useEffect } from "react";
import { Toolbar, ListItem, IconButton, Grid, Skeleton, Pagination } from "@mui/material";
import { faInfoCircle, faPen, faTrash, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { sitesRoutes, usersRoutes } from '../../routes';
import { changeTypeUser } from '../../config';
import { UserListDataModal } from '../../modals'
import { LoadingPage } from "../../pages";
import { useSitesContext, useUsersContext } from "../../context";
import { UserListDataDialog } from "../../dialogs";
import * as XLSX from 'xlsx'

const UsersList = ({height, hableButton, email, nombre, obra, run, test, firma, faenaSeleccionada, usuariosFiltradosPorRol, exportUsuarios}) => {
    const {users, loadingUsers} = useUsersContext()
    const {sites} = useSitesContext()
    const [ usuarios, setUsuarios ] = useState([])
    const [ usuariosCache, setUsuariosCache ] = useState([])
    const [ open, setOpen ] = useState(false);
    const [ userModal, setUserModal ] = useState({});
    const [ indice, setIndice ] = useState(0);
    const [ checked, setCheck ] = useState(false);
    const [ permissionsReports, setPermissionsReports ] = useState([]);
    const [ permissionsUsers, setPermissionsUsers ] = useState([])
    const [skeletons, setSkeletons] = useState([])
    const [group, setGroup] = useState()
    const [pages, setPages] = useState(0)
    const [page, setPage] = useState(1)
    const [estaFiltrando, setEstaFiltrando] = useState(false)

    useEffect(() => {
        const skeletonsCache = []
        for(let i = 0; i < 14; i++) {
            skeletonsCache.push(i)
        }
        setSkeletons(skeletonsCache)
    }, [])

    useEffect(() => {
        if (exportUsuarios) {
            const rows = usuariosCache.map(usuario => {
                const newRow = {
                    'Nombre': usuario.name,
                    'Apellido': usuario.lastName,
                    'R.U.T': usuario.rut,
                    'Firma': usuario.sign ? 'SI' : 'NO',
                }
                return newRow
            })
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(rows);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Usuarios");
            XLSX.writeFile(workbook, `usuarios.xlsx`)
    
        }
    }, [exportUsuarios])

    useEffect(() => {
        setUsuariosCache(users)
    }, [users])

    useEffect(() => {
        if (group) {
            setUsuarios(group[page])
        }
    }, [page, group])

    const handleChange = (event) => {
        setCheck(event.target.checked);
        usuarios[indice].enabled = event.target.checked
    }

    const openModal = (user, i) => {
        setUserModal(user);
        setCheck(user.enabled)
        setPermissionsReports(user.permissionsReports)
        setPermissionsUsers(user.permissionsUsers)
        setIndice(i)
        setOpen(true)
    }
    const closeModal = (user) => {
        setOpen(false);
        actualiceUser(user)
    }

    const actualiceUser = (user) => {
        usersRoutes.editUser(user).then(response=>{

        })
    }

    useEffect(() => {
        let usuariosTem = []

        if (usuariosFiltradosPorRol.length > 0) {
            usuariosTem = usuariosFiltradosPorRol
        } else {
            usuariosTem = users
        }

        if (faenaSeleccionada.length > 0) {
            usuariosTem = buscarPorFaena(faenaSeleccionada, usuariosTem)
        }

        if (nombre) {
            usuariosTem = buscarUsuario(nombre, usuariosTem)
        }
        if (email) {
            usuariosTem = buscarPorCorreo(email, usuariosTem)
        }
        if (run) {
            usuariosTem = buscarPorRut(run, usuariosTem)
        }
        if (test) {
            usuariosTem = buscarTest(usuariosTem)
        }
        if (firma) {
            usuariosTem = buscarFirma(usuariosTem, firma)
        }
        if (usuariosTem.length === usuariosCache.length) {
            setEstaFiltrando(false)
        } else {
            setEstaFiltrando(true)
        }
        console.log(usuariosTem)
        setUsuariosCache(usuariosTem)
    }, [email, nombre, obra, run, firma, test, usuariosFiltradosPorRol, faenaSeleccionada])

    useEffect(() => {
        if (usuariosCache.length > 0) {
            const usersGroup = {}
            let initIndex = 0
            let numberUsersByGroup = 14
            const pagesCache = (Math.floor(usuariosCache.length / 14) + 1)
            setPages(pagesCache)
            for (let i = 1; i < (pagesCache + 1); i++) {
                if (!usersGroup[i]) {
                    usersGroup[i] = usuariosCache.slice(initIndex, (numberUsersByGroup))
                    initIndex = initIndex + 14
                    numberUsersByGroup = numberUsersByGroup + 14
                }
            }
            setGroup(usersGroup)
        } else {
            if (estaFiltrando) {
                const usersGroup = {}
                let initIndex = 0
                let numberUsersByGroup = 14
                const pagesCache = (Math.floor(usuariosCache.length / 14) + 1)
                setPages(pagesCache)
                for (let i = 1; i < (pagesCache + 1); i++) {
                    if (!usersGroup[i]) {
                        usersGroup[i] = usuariosCache.slice(initIndex, (numberUsersByGroup))
                        initIndex = initIndex + 14
                        numberUsersByGroup = numberUsersByGroup + 14
                    }
                }
                setGroup(usersGroup)
            }
        }
    }, [usuariosCache, estaFiltrando]);

    const changePage = (e, value) => {
        setPage(Number(value))
    }


    const readAllUsers = () => {
        return new Promise(resolve => {
            usersRoutes.getAllUsers().then(users=> {
                resolve(users)
            })
        })
    }

    const deleteUser = (userId, userName, userLastName, userRole) => {
        if(userRole === 'admin') {
            alert('No es posible eliminar un Administrador.')
        }else{
            if(confirm(`Removerá al usuario ${userName} ${userLastName}. Confirme la acción.`)) {
                usersRoutes.removeUser(userId).then(async data=>{
                    if(data) {
                        setUsuarios((await readAllUsers()).data)
                    }
                })
            }
        }
    }

    const well = {
        /* height: 70, */
        borderRadius: 10,
        boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.08)',
        fontSize: 14
    }

    const buscarUsuario = (value = new String(), usuariosTem) => {
        console.log('Nombre: ', value)
        if (value.length > 3) {
            const usuarios = usuariosTem.filter(usuario => {
                const name = usuario.name + ' ' + usuario.lastName
                if (name.toUpperCase().match(value.toUpperCase())) {
                    return usuario
                } else {
                    return null
                }
            })
            return usuarios
        } else {
            const usuarios = usuariosTem
            return usuarios
        }
    }

    const buscarPorFaena = (value = new String(), usuariosTem) => {
        console.log('Nombre: ', value)
        if (value.length > 3) {
            const usuarios = usuariosTem.filter(usuario => {
                if (usuario.obras && usuario.obras[0] && usuario.obras[0].descripcion) {
                    if (usuario.obras[0].descripcion === value) {
                        return usuario
                    }
                }
                
            })
            return usuarios
        } else {
            const usuarios = usuariosTem
            return usuarios
        }
    }

    const buscarPorCorreo = (value = new String(), usuariosTem) => {
        console.log('Correo: ', value)
        if (value.length > 3) {
            const usuarios = usuariosTem.filter(usuario => {
                const email = usuario.email
                if (email) {
                    if (email.toUpperCase().match(value.toUpperCase())) {
                        return usuario
                    } else {
                        return null
                    }
                } else {
                    return null
                }
            })
            return usuarios
        } else {
            const usuarios = usuariosTem
            return usuarios
        }
    }

    const buscarPorRut = (value = new String(), usuariosTem) => {
        console.log('Rut: ', value)
        if (value.length > 3) {
            const usuarios = usuariosTem.filter(usuario => {
                if (usuario.rut) {
                    if (usuario.rut.match(value)) {
                        return usuario
                    } else {
                        return null
                    }
                } else {
                    return null
                }
            })
            return usuarios
        } else {
            const usuarios = usuariosTem
            return usuarios
        }
    }

    const buscarTest = (usuariosTem) => {
        const usuarios = usuariosTem.filter(usuario => {
            if (usuario.isTest) {
                return usuario
            } else {
                return null
            }
        })
        return usuarios
    }

    const buscarFirma = (usuariosTem, firma) => {
        const usuarios = usuariosTem.filter(usuario => {
            if (firma === 'Sin Firma') {
                if (!usuario.sign) {
                    return usuario
                } else {
                    return null
                }
            } else if (firma === 'Con firma') {
                if (usuario.sign) {
                    return usuario
                } else {
                    return null
                }
            } else {
                return usuario
            }
        })
        return usuarios
    }

    const buscarPorRol = (value) => {
        console.log(value)
        if (value === 'TODOS LOS ROLES') {
            setUsuarios(usuariosCache)
        } else {
            const usuarios = usuariosCache.filter(usuario => {
                if (usuario.roles && usuario.roles.includes(value)) {
                    return usuario
                } else {
                    return null
                }
            })
            setUsuarios(usuarios)
        }
    }

    const buscarPorObra = (value) => {
        console.log(value)
        if (value === 'TODAS LAS OBRAS') {
            setUsuarios(usuariosCache)
        } else {
            const usuarios = usuariosCache.filter(usuario => {
                if (usuario.obras[0] && usuario.obras[0].descripcion) {
                    if (usuario.obras[0].descripcion.match(value)) {
                        return usuario
                    } else {
                        return null
                    }
                } else {
                    return null
                }
            })
            setUsuarios(usuarios)
        }
    }

    return (
        <div style={{height: 'calc(100vh - 200px)', position: 'relative'}}>
            <UserHeader />
            {
                hableButton && <div style={{overflowY: 'auto', height: 'calc(100vh - 300px)'}}>
                    {
                        loadingUsers && 
                        skeletons.map(i => {
                            return (
                                <LoadingSkeleton key={i} />
                            )
                        })
                    }
                    {
                        ((usuarios && usuarios.length > 0)&&!loadingUsers) && usuarios.map((e, n) => {
                            return(
                                <UserItem key={n} user={e} index={n} openModal={openModal} deleteUser={deleteUser}/>
                            )
                        })
                    }
                    {
                        userModal && <UserListDataDialog 
                            open={open} 
                            userModal={userModal} 
                            handleChange={handleChange} 
                            checked={checked}
                            permissionsReports={permissionsReports}
                            permissionsUsers={permissionsUsers}
                            closeModal={closeModal}
                            />
                    }
            </div>
            }
            {
                !hableButton && 
                <div style={{textAlign: 'center', width: '100%', height: '50%', paddingTop: 50}}>
                    <h1>Requiere conexión a internet para administración de usuarios</h1>
                </div>
            }
            <Pagination
                style={{
                    position:'absolute',
                    right: 20,
                    bottom: 20
                }}
                count={pages}
                page={page}
                onChange={changePage}
                color="primary"
            />
        </div>
    )
}

const UserHeader = () => {
    return (
        <Grid container style={{fontSize: 10, borderBottom: '1px #ccc solid'}}>
            <Grid item xs={'auto'}>
                <div style={{width: 30, marginTop: 5}}>
                </div> 
            </Grid>
            <Grid item xs={2}>
                <p>
                    <strong>Nombre de usuario</strong>
                </p>
            </Grid>
            <Grid item xs={2}>
                <p>
                    <strong>Correo electrónico</strong>
                </p>
            </Grid>
            <Grid item xs={1}>
                <p>
                    <strong>RUN</strong>
                </p>
            </Grid>
            <Grid item xs={2}>
                <p>
                    <strong>Rol</strong>
                </p>
            </Grid>
            <Grid item xs={2}>
                <p>
                    <strong>Faena / Obra</strong>
                </p>
            </Grid>
            <Grid item xs={1}>
                <p>
                    <strong>Estado</strong>
                </p>
            </Grid>
            <Grid item xs={1}>
                
            </Grid>
        </Grid>
    )
}

const UserItem = ({user, index, openModal, deleteUser}) => {
    const [e, setUser] = useState()
    useEffect(() => {
        setUser(user)
    }, [user])
    return (
        <>
            {e &&

            <Grid container style={{fontSize: 10, borderBottom: '1px #ccc solid'}}>
                <Grid item xs={'auto'}>
                    <div style={{width: 30, marginTop: 5}}>
                        <img style={{height: 25, borderRadius: '50%', objectFit: 'cover', width: 25}} src={!e.imageUrl ? '../assets/no-profile-image.png' : e.imageUrl} alt="" />
                    </div> 
                </Grid>
                <Grid item xs={2}>
                    <p>
                        {e.name} {e.lastName}
                    </p>
                </Grid>
                <Grid item xs={2}>
                    <p>
                        {e.email === 'x@x.xx' ? '----' : e.email}
                    </p>
                </Grid>
                <Grid item xs={1}>
                    <p>
                        {e.rut}
                    </p>
                </Grid>
                <Grid item xs={2}>
                    {
                        (e.role && (e.role.length > 0))
                        ?
                        <p>
                            {changeTypeUser(e.role)}
                        </p>
                        :
                        e.roles.map((role, number) => {
                            return (
                                <p key={number} /* style={{ margin: 0 }} */>
                                    {
                                        `${changeTypeUser(role)}${number === (e.roles.length - 1) ? '' : '\n'}`
                                    }
                                </p>
                            )
                        })
                    }
                </Grid>
                <Grid item xs={2}>
                    <p>
                        {(e.obras && e.obras[0]) ? e.obras[0].descripcion : ''}
                    </p>
                </Grid>
                <Grid item xs={1}>
                    {
                        e.enabled &&
                        <p style={{borderRadius: 5, maxWidth: 200, textAlign: 'center', backgroundColor: '#C3EBD4', paddingTop: 3, paddingRight: 28, paddingBottom: 3, paddingLeft: 28}}>
                            ACTIVO
                        </p>
                    }
                    {
                        !e.enabled &&
                        <p style={{borderRadius: 5, maxWidth: 200, textAlign: 'center', backgroundColor: '#F9F9F9', paddingTop: 3, paddingRight: 28, paddingBottom: 3, paddingLeft: 28}}>
                            INACTIVO
                        </p>
                    }
                </Grid>
                <Grid item xs={1}>
                    <IconButton title="Información del usuario" onClick={() => openModal(e, index)}>
                        <FontAwesomeIcon style={{width: 16}} icon={faInfoCircle}/>
                    </IconButton>
                    <Link to={`/edit-user/${e._id}`}>
                        <IconButton title="Editar usuario">
                            <FontAwesomeIcon style={{width: 16}} icon={faPen}/>
                        </IconButton>
                    </Link>
                    <IconButton title="Borrar usuario" onClick={()=>{deleteUser(e._id, e.name, e.lastName, e.role)}}>
                        <FontAwesomeIcon style={{width: 16}} icon={faTrash}/>
                    </IconButton>
                </Grid>
            </Grid>}
        </>
    )
}

const LoadingSkeleton = () => {
    return (
        <div style={{height: 41, borderBottom: '1px #ccc solid', width: '100%'}}>
            <div style={{height: 38, width: '100%'}}>
                <Skeleton variant="rounded" width={'100%'} height={'100%'} />
            </div>
        </div>
    )
}

export default UsersList
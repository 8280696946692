import { Button, ButtonGroup, Dialog, DialogContent, DialogContentText, DialogTitle, Fab, Grid, IconButton, Input, TextareaAutosize } from "@mui/material"
import { Close, Upload } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { dateWithTime, imageToBase64 } from '../config';
import { useAuth, useConnectionContext, useExecutionReportContext } from "../context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css'
import ImageDialog from "./ImageDialog";
import ImageAstDialog from "./ImageAstDialog";

const BacklogMessageDialog = ({open, close, item, report}) => {
    const {userData} = useAuth()
    const {isOnline} = useConnectionContext()
    const {messageBacklog, setMessageBacklog} = useExecutionReportContext()

    const [mess, setMess] = useState('')
    const [images, setImages] = useState([])
    const [files, setFiles] = useState([])
    const [sliderIndex, setSliderIndex] = useState()
    const [itemParaMostrar, setItemParaMostrar] = useState()
    const [imagenesEnLogs, setImagenesEnLog] = useState([])
    const [openImageModel, setOpenImageModel] = useState(false)
    const [imageSelected, setImageSelected] = useState(null)
    const [imagesSelected, setImagesSelected] = useState([])
    const [indexImage, setIndexImage] = useState(0)
    const [seleccionComponente, setSeleccionComponente] = useState('nuevoBacklog')

    useEffect(() => {
        console.log(imagenesEnLogs)
    }, [imagenesEnLogs])

    useEffect(() => {
        if (!open) {
            setImages([])
        }
    }, [open])

    useEffect(() => {
        console.log(userData)
        console.log(item)
        if (item) {
            setItemParaMostrar(item)
        }
    }, [item, userData])

    const fechaCompleta = (time) => {
        if(time) {
            const fecha = new Date(time).toISOString()
            let day = new Date(fecha).getDay()
            let dayName;
            if(day === 0) {
                dayName = 'Dom'
            }else if(day === 1) {
                dayName = 'Lun'
            }else if(day === 2) {
                dayName = 'Mar'
            }else if(day === 3) {
                dayName = 'Mie'
            }else if(day === 4) {
                dayName = 'Jue'
            }else if(day === 5) {
                dayName = 'Vie'
            }else if(day === 6) {
                dayName = 'Sab'
            }
            
            let date = new Date(fecha).getUTCDate();
            let month = new Date(fecha).getMonth();
            let monthName
            if(month == 0) {
                monthName = 'Jan'
            }else if(month == 1) {
                monthName = 'Feb'
            }else if(month == 2) {
                monthName = 'Mar'
            }else if(month == 3) {
                monthName = 'Apr'
            }else if(month == 4) {
                monthName = 'May'
            }else if(month == 5) {
                monthName = 'Jun'
            }else if(month == 6) {
                monthName = 'Jul'
            }else if(month == 7) {
                monthName = 'Aug'
            }else if(month == 8) {
                monthName = 'Sep'
            }else if(month == 9) {
                monthName = 'Oct'
            }else if(month == 10) {
                monthName = 'Nov'
            }else if(month == 11) {
                monthName = 'Dic'
            }
            let year = new Date(fecha).getUTCFullYear();
            return `${date}-${monthName}-${year}`
        }else{
            return 'Sin fecha'
        }
    }

    const removeImage = (index) => {
        const filesCache = []
        const imagesCache = []

        images.forEach((image, i) => {
            if (i !== index) {
                imagesCache.push(image)
            }
        })
        files.forEach((file, i) => {
            if (i !== index) {
                filesCache.push(file)
            }
        })
        setFiles(filesCache)
        setImages(imagesCache)
    }

    const deleteMessage = (index) => {
        if (confirm('Confirme el borrado del mensaje. La acción no se podrá reponer.')) {
            const lista = []
            itemParaMostrar.backlogList.forEach((e, i) => {
                if (index !== i) {
                    lista.push(e)
                }
            })
            setItemParaMostrar({...itemParaMostrar, backlogList: lista})
        }
    }

    const enviarBacklog = async () => {
        if(messageBacklog.length > 0) {
            const itemCache = itemParaMostrar
            if (!itemCache.backlogList) {
                itemCache.backlogList = []
            }
            const fileList = await Promise.all(files.map(async (image)=> {
                const url = await imageToBase64(image)
                return url
            }))
            const backlog = {
                user: {
                    _id: userData._id,
                    name: userData.name,
                    lastName: userData.lastName
                },
                images: files,
                fileList: fileList,
                message: messageBacklog,
                id: Date.now()
            }
            itemCache.backlogList.push(backlog)
            setItemParaMostrar(itemCache)
            setMessageBacklog('')
            setImages([])
            
            close(itemCache, true)
        } else {
            alert('Debe escribir mensaje.')
        }
    }

    const addImage = () => {
        if (images.length < 5) {
            const imageButton = document.getElementById("imageBacklog")
            if (imageButton) {
                imageButton.click()
            }
        } else {
            alert('5 fotos como máximo')
        }
    }

    const getImages = (e) => {
        let filesCache = []
        console.log(Array.from(e.target.files))
        if (Array.from(e.target.files).length < 6) {
            if ((images.length + Array.from(e.target.files).length) < 6) {
                filesCache = Array.from(e.target.files)
                setFiles(filesCache)
                filesCache.forEach(async (file, i) => {
                    const urlBase64 = await imageToBase64(file);
                    setImages(images => [...images, {
                        urlBase64: urlBase64,
                        url: null
                    }])
                });
            } else {
                alert('5 fotos como máximo')
            }
        } else {
            alert('5 fotos como máximo')
        }
    }

    const openImage = (images, index) => {
        setOpenImageModel(true)
        setImagesSelected(images)
        setIndexImage(index)
    }

    const closeImage = () => {
        setOpenImageModel(false)
        setImagesSelected([])
        setIndexImage(0)
    }

    return(
        <Dialog
            open={open}
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "700px",
                    height: "100%",
                    maxHeight: "600px"
                  },
                }
              }}
        >
           {openImageModel && <ImageAstDialog
                open={openImageModel}
                handleClose={closeImage}
                images={imagesSelected}
                indexSlide={indexImage}
            />}
            <div style={{padding: 10}} >
                {
                    seleccionComponente === 'nuevoBacklog'
                    ?
                    <div style={{width: '100%'}}>
                        <DialogTitle>
                            Backlog
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {itemParaMostrar && itemParaMostrar.strpmdesc} / {itemParaMostrar && itemParaMostrar.taskdesc}
                            </DialogContentText>
                            <br />
                            <Grid container>
                                <Grid item xs={11} md={8}>
                                    <TextareaAutosize 
                                        aria-label="empty textarea"
                                        placeholder="Deje su comentario"
                                        minRows={5}
                                        maxRows={10}
                                        style={{ width: '100%', fontSize: 18, fontFamily: 'Arial, Helvetica, sans-serif' }}
                                        value={messageBacklog}
                                        onChange={(e)=>{setMessageBacklog(e.target.value)}}
                                    />
                                </Grid>
                                <Grid item xs={1} md={1}>
                                    <IconButton onClick={addImage} style={{marginBottom: 30}}>
                                        <FontAwesomeIcon icon={faCamera} />
                                    </IconButton>
                                    <input 
                                        onChange={getImages} 
                                        multiple 
                                        type="file"
                                        style={{display: 'none'}} 
                                        id={'imageBacklog'} 
                                        accept="image/jpeg"
                                        />
                                </Grid>
                                <Grid item xs={12} md={3} style={{fontSize: 12}}>
                                    <Grid container>
                                        <h5 style={{margin: '2px 0px'}}>
                                            Datos de usuario:
                                        </h5>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            Nombre:
                                        </Grid>
                                        <Grid item xs={8}>
                                            {userData.name} {userData.lastName}
                                        </Grid>
                                        <Grid item xs={4}>
                                            Rut:
                                        </Grid>
                                        <Grid item xs={8}>
                                            {userData.rut}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                            <div style={{width: '100%'}}>
                                <Swiper
                                    grabCursor={true}
                                    effect={"creative"}
                                    creativeEffect={{
                                    prev: {
                                        shadow: true,
                                        translate: [0, 0, -400],
                                    },
                                    next: {
                                        translate: ["100%", 0, 0],
                                    },
                                    }}
                                    zoom={true}
                                    modules={[Navigation]}
                                    spaceBetween={50}
                                    slidesPerView={2.5}
                                    navigation
                                    keyboard={{
                                    enabled: true,
                                    }}
                                    style={{
                                        width: '100%',
                                        "--swiper-navigation-color": "#be2e26",
                                        "--swiper-pagination-color": "#be2e26",
                                    }}
                                    pagination={{ clickable: true }} 
                                    onSwiper={(swiper) => setSliderIndex(swiper.activeIndex)}
                                    onSlideChange={(e) => setSliderIndex(e.activeIndex)}
                                >
                                    {
                                        images.map((element, index) => {
                                            return (
                                                <SwiperSlide key={index} style={{display: 'block', width: '100%', textAlign: 'center'}}>
                                                    <div style={{position: 'relative'}}>
                                                        <button onClick={() => {removeImage(index)}} style={{position: 'absolute', right: 10, top: 10, cursor: 'pointer'}}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                        <img 
                                                            /* onClick={() => {openImage((element.url) ? element.url :  element.urlBase64, index)}} */
                                                            key={index}
                                                            src={(element.url) ? element.url :  element.urlBase64}
                                                            style={{width: '100%', maxHeight: 180, textAlign: 'center', objectFit: 'cover'}}
                                                        />
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            </div>
                            </Grid>
                            <br />
                            <Button variant={'contained'} style={{width: '100%'}} onClick={()=>enviarBacklog()}>
                                Enviar Backlog
                            </Button>
                        </DialogContent>
                    </div>
                    :
                    <div style={{width: '100%'}}>
                        <DialogTitle>
                            Mensajes
                        </DialogTitle>
                        <DialogContent>
                            {itemParaMostrar && itemParaMostrar.backlogList && (itemParaMostrar.backlogList.length > 0) && <div>
                                {
                                    itemParaMostrar && itemParaMostrar.backlogList && itemParaMostrar.backlogList.map((el, i) => {
                                        console.log(el)
                                        return (
                                            <div key={i} style={{position: 'relative', border: '1px #ccc solid', borderRadius: 8, padding: 5, marginBottom: 10}}>
                                                <div style={{width: '100%'}}>
                                                    <p style={{margin: 0, fontSize: 12}}>
                                                        <strong>
                                                            - {el.user.name} {el.user.lastName}
                                                        </strong>
                                                    </p>
                                                </div>
                                                <div style={{width: '100%'}}>
                                                    <p style={{margin: 0, whiteSpace: 'pre-line'}}>
                                                        {el.message}
                                                    </p>
                                                </div>
                                                <div style={{width: '100%', textAlign: 'right', fontSize:12}}>
                                                    <p style={{margin: 0}}>
                                                        {dateWithTime(el.id)}
                                                    </p>
                                                </div>
                                                <Grid container>
                                                    {
                                                        el.fileList && el.fileList.map((image, index) => {
                                                            return (
                                                                <Grid item xs={2} key={index} style={{margin: '0px 10px'}}>
                                                                    <img src={image} style={{width:'100%', height: '100%'}}
                                                                        onClick={() => {openImage(el.fileList, index)}}
                                                                    />
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                                {
                                                    (userData._id === el.user._id) && <IconButton style={{position: 'absolute', top: 0, right: 0, fontSize:10}} onClick={() => {deleteMessage(i)}}>
                                                    <Close style={{fontSize: 16}}/>
                                                </IconButton>}
                                            </div>
                                        )
                                    })
                                }
                            </div>}
                        </DialogContent>
                    </div> 
                }
                <Fab onClick={()=>close()} style={{position: 'absolute', right: 10, top: 10, boxShadow: 'none', backgroundColor: 'transparent'}}>
                    <Close style={{color: '#ccc'}} />
                </Fab>
                
                <ButtonGroup variant="outlined" aria-label="Basic button group" style={{position: "absolute", bottom: 0, left: 0, width: '100%'}}>
                    <Button style={{width: '100%'}} onClick={() => {setSeleccionComponente('nuevoBacklog')}}>Nuevo Backlog</Button>
                    <Button style={{width: '100%'}} onClick={() => {setSeleccionComponente('mensajes')}}>Mensajes</Button>
                </ButtonGroup>
            </div>
        </Dialog>
    )
}

export default BacklogMessageDialog
import React, {useState, useEffect} from 'react';
import { Box, Card, Grid, Toolbar, IconButton, Drawer, TextField, Button, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { changeTypeUser, useStylesTheme } from '../../config';
import { useNavigate } from 'react-router-dom';
import { UsersList } from '../../containers';
import './style.css'
import { faFilter, faUserPlus, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth, useUsersContext } from '../../context';

const AdminUsersPage = ({route}) => {
    const {userData} = useAuth()
    const {users} = useUsersContext()
    const [ isConnected, setIsConnected ] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)
    const [nombre, setNombre] = useState('')
    const [email, setEmail] = useState('')
    const [run, setRun] = useState('')
    const [firma, setFirma] = useState('Todas')
    const [roles, setRoles] = useState([])
    const [faenas, setFaenas] = useState([])
    const [roleSeleccionado, setRolSeleccionado] = useState('')
    const [faenaSeleccionada, setFaenaSeleccionada] = useState('')
    const [test, buscarTestUsuarios] = useState(false)
    const [listaRoles, setListaRoles] = useState([])
    const [listaFaenas, setListaFaenas] = useState([])
    const [usuariosFiltradosPorRol, setUsuariosFiltradosPorRol] = useState([])

    const [exportUsuarios, setExportUsuarios] = useState(false)
    /* const [usuariosFiltradosPorFaena, setUsuariosFiltradosPorFaena] = useState([]) */

    const navigate = useNavigate();

    useEffect(() => {
        if (exportUsuarios) {
            setTimeout(() => {
                setExportUsuarios(false)
            }, 1000);
        }
    }, [exportUsuarios])

    useEffect(() => {
        console.log(userData)
        if(navigator.onLine) {
            setIsConnected(true)
        }else{
            setIsConnected(false)
        }
    }, [])

    useEffect(() => {
        console.log(users)
        if (users.length > 0) {
            crearListaRoles()
        }
    }, [users])

    useEffect(() => {
        if (roleSeleccionado.length > 0) {
            setUsuariosFiltradosPorRol(listaRoles[roleSeleccionado])
        } else {
            setUsuariosFiltradosPorRol([])
        }
    }, [roleSeleccionado])

    const crearListaRoles = () => {
        const listaRolesCache = {}
        const listaFaenasCache = {}
        users.forEach(user => {
            user.roles.forEach((rolUsuario) => {
                if (!listaRolesCache[rolUsuario]) {
                    listaRolesCache[rolUsuario] = []
                }
                listaRolesCache[rolUsuario].push(user)
            })
            user.obras.forEach((obra) => {
                if (!listaFaenasCache[obra.descripcion]) {
                    listaFaenasCache[obra.descripcion] = []
                }
                listaFaenasCache[obra.descripcion].push(user)
            })
        })
        console.log(listaRolesCache)
        setListaRoles(listaRolesCache)
        setListaFaenas(listaFaenasCache)
        setRoles(
            Object.keys(listaRolesCache).filter((key) => {
                if (key !== null || key !== 'null') {
                    return key
                }
            })
        )
        setFaenas(
            Object.keys(listaFaenasCache)
        )
    }

    const buscarPorNombre = (e) => {
        setNombre(e.target.value)
    }

    const buscarPorEmail = (e) => {
        setEmail(e.target.value)
    }

    const buscarPorRun = (e) => {
        setRun(e.target.value)
    }

    const buscarUsuarioFirma = (e) => {
        setFirma(e.target.value)
    }

    const borrarTodo = () => {
        setNombre('')
        setEmail('')
        setRun('')
        setFirma('Todas')
    }


    return (
        <Box height='100%'>
            <Drawer open={openMenu} onClose={() => {setOpenMenu(false)}} anchor='right' variant={'persistent'}>
                <div style={{width: 250, height: '100%', padding: '40px 20px 20px 20px', position: 'relative'}}>
                    <IconButton style={{position: 'absolute', left: 10, top: 10}}
                        onClick={() => {setOpenMenu(false)}}
                    >
                        <FontAwesomeIcon icon={faWindowClose} />
                    </IconButton>
                    <div>
                        <p style={{fontSize: 16}}>
                            <strong>
                            Filtro de búsqueda
                            </strong>
                        </p>
                    </div>
                    <TextField
                        id="outlined-basic"
                        label="Buscar por nombre"
                        variant="outlined"
                        name={'name'}
                        onChange={buscarPorNombre}
                        value={nombre}
                        style={{marginBottom: 10}}
                    />
                    <TextField
                        id="outlined-basic"
                        label="Buscar por email"
                        variant="outlined"
                        name={'email'}
                        value={email}
                        onChange={buscarPorEmail}
                        style={{marginBottom: 10}}
                    />
                    <TextField
                        id="outlined-basic"
                        label="Buscar por run"
                        variant="outlined"
                        name={'run'}
                        value={run}
                        onChange={buscarPorRun}
                        style={{marginBottom: 10}}
                    />
                    <FormControl fullWidth 
                        style={{marginBottom: 10}}>
                        <InputLabel id="demo-simple-select-label">Rol</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={roleSeleccionado}
                            label="Rol"
                            onChange={(e) => {setRolSeleccionado(e.target.value)}}
                        >
                            <MenuItem value={''}>Todos</MenuItem>
                            {
                                roles.map(rol => {
                                    if (rol !== 'null') {
                                        if (rol !== 'superAdmin') {
                                            return (
                                                <MenuItem value={rol} key={rol}>{changeTypeUser(rol)}</MenuItem>
                                            )
                                        }
                                    }
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth 
                        style={{marginBottom: 10}}>
                        <InputLabel id="demo-simple-select-label">Firma</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={firma}
                            label="Rol"
                            onChange={(e) => {setFirma(e.target.value)}}
                        >
                            <MenuItem value={'Todas'}>Todas</MenuItem>
                            <MenuItem value={'Con firma'}>Con firma</MenuItem>
                            <MenuItem value={'Sin Firma'}>Sin Firma</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth 
                        style={{marginBottom: 10}}>
                        <InputLabel id="demo-simple-select-label">Obra / Faena</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={faenaSeleccionada}
                            label="Obra / Faena"
                            onChange={(e) => {setFaenaSeleccionada(e.target.value)}}
                        >
                            <MenuItem value={''}>Todos</MenuItem>
                            {
                                faenas.map(faena => {
                                    return (
                                        <MenuItem value={faena} key={faena}>{faena}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    {
                        userData.isTest && <Button onClick={() => {buscarTestUsuarios(true)}}>
                            Solo test
                        </Button>
                    }
                    <Button onClick={setExportUsuarios} variant={'contained'} size={'large'} style={{width: '100%', marginTop: 10}}>
                        Exportar
                    </Button>
                    <Button onClick={borrarTodo} variant={'contained'} size={'large'} style={{width: '100%', marginTop: 10}}>
                        Borrar Filtro
                    </Button>
                </div>
            </Drawer>
            <Grid className={'root'} container spacing={0}>
                <Grid className={'pageContainer'} item xs={12}>
                    <Card elevation={0} className={'pageCard'}>
                        <div style={{width: '100%', textAlign: 'left', padding: 10 }}>
                            <div style={{width: '100%', textAlign: 'left', color: '#333', backgroundColor: '#fff', borderRadius: 20 }}>
                                <Toolbar style={{paddingLeft: 0, backgroundColor: '#F9F9F9', borderRadius: 10, position: 'relative'}}>
                                    <IconButton onClick={() => setTimeout(() => {
                                        navigate(-1)
                                    }, 500)}>
                                        <ArrowBackIos style={{color: '#333', fontSize: 16}}/> 
                                    </IconButton>
                                    <h1 style={{marginTop: 0, marginBottom: 0, fontSize: 16}}>
                                        Administración / Administrar usuarios
                                    </h1>
                                    <IconButton
                                        style={{position: 'absolute', right: 70}}
                                        color={'primary'} 
                                        edge={'end'}
                                        title='Agregar Usuario'
                                        onClick={() => {navigate('/new-users')}}
                                    >
                                        <FontAwesomeIcon style={{zIndex: 10}} icon={faUserPlus}/>
                                    </IconButton>
                                    <IconButton
                                        style={{position: 'absolute', right: 30}}
                                        color={'primary'} 
                                        edge={'end'}
                                        title='Filtrar'
                                        onClick={() => {setOpenMenu(true)}}
                                    >
                                        <FontAwesomeIcon style={{zIndex: 10}} icon={faFilter}/>
                                    </IconButton>
                                </Toolbar>
                            </div>
                        </div>
                        <div style={{width: '100%', textAlign: 'left', padding: 20, overflowY: 'auto', height: '100%'}}>
                            <UsersList 
                                height={'100%'} 
                                hableButton={isConnected} 
                                nombre={nombre} 
                                email={email}
                                run={run}
                                test={test}
                                firma={firma}
                                faenaSeleccionada={faenaSeleccionada}
                                usuariosFiltradosPorRol={usuariosFiltradosPorRol}
                                exportUsuarios={exportUsuarios}
                                />
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AdminUsersPage
import { forwardRef } from "react"
import { Button, Dialog, DialogContent, Grid, Slide } from "@mui/material"


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export const ConfirmarDialog = ({open, handleClose, message, select}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "300px",  // Set your width here
                    },
                  }
            }}
        >
            <DialogContent>
                <p>{message}</p>
                <Grid container style={{width:'100%'}}>
                    <Grid item sm={6} style={{textAlign: 'left'}}>
                        <Button variant={'outlined'} onClick={handleClose}>
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item sm={6} style={{textAlign: 'right'}}>
                        <Button variant={'contained'} onClick={select}>
                            Continuar
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>

    )
}
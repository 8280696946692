export const ColumnasReportes = [
    {
        value: 'idIndex',
        name: 'N° OT',
        index: null,
        checked: false,
        isDate: false,
        width: 55,
        restrict: ['Origen', 'Asignar', 'En proceso', 'Por cerrar', 'Completadas']
    },
    {
        value: 'idPm',
        name: 'PM',
        index: null,
        checked: false,
        isDate: false,
        width: 70,
        restrict: ['Origen', 'Asignar', 'En proceso', 'Por cerrar', 'Completadas']
    },
    {
        value: 'estado',
        name: 'Estado',
        index: null,
        checked: false,
        isDate: false,
        width: 100,
        restrict: ['Origen', 'Asignar', 'En proceso', 'Por cerrar', 'Completadas']
    },
    {
        value: 'usersAssigned',
        name: 'Usuarios Asignados',
        index: null,
        checked: false,
        isDate: false,
        width: 170,
        restrict: ['Asignar', 'En proceso', 'Por cerrar', 'Completadas']
    },
    {
        value: 'chiefMachineryApprovedBy',
        name: 'Jefe de Maquinaria',
        index: null,
        checked: false,
        isDate: false,
        width: 170,
        restrict: ['Por cerrar', 'Completadas']
    },
    {
        value: 'sapExecutiveApprovedBy',
        name: 'Ejecutivo SAP',
        index: null,
        checked: false,
        isDate: false,
        width: 170,
        restrict: ['Completadas']
    },
    {
        value: 'shiftManagerApprovedBy',
        name: 'Jefe de turno',
        index: null,
        checked: false,
        isDate: false,
        width: 170,
        restrict: ['En proceso', 'Por cerrar', 'Completadas']
    },
    /* {
        value: 'isAutomatic',
        name: 'Creada por sistema',
        index: null,
        checked: false,
        width: 130
    }, */
    {
        value: 'machine',
        name: 'N° Máquina',
        index: null,
        checked: false,
        isDate: false,
        width: 140,
        restrict: ['Origen', 'Asignar', 'En proceso', 'Por cerrar', 'Completadas']
    },
    {
        value: 'guide',
        name: 'Pauta',
        index: null,
        checked: false,
        isDate: false,
        width: 55,
        restrict: ['Origen', 'Asignar', 'En proceso', 'Por cerrar', 'Completadas']
    },
    {
        value: 'datePrev',
        name: 'Fecha Prevista',
        index: null,
        checked: false,
        isDate: true,
        width: 105,
        restrict: ['Origen', 'Asignar', 'En proceso', 'Por cerrar', 'Completadas']
    },
    {
        value: 'dateInit',
        name: 'Fecha Inicio',
        index: null,
        checked: false,
        isDate: true,
        width: 105,
        restrict: ['Origen', 'Asignar', 'En proceso', 'Por cerrar', 'Completadas']
    },
    {
        value: 'endReport',
        name: 'Fecha Término',
        index: null,
        checked: false,
        isDate: true,
        width: 105,
        restrict: ['Origen', 'Asignar', 'En proceso', 'Por cerrar', 'Completadas']
    },
    {
        value: 'dateClose',
        name: 'Fecha Cierre',
        index: null,
        checked: false,
        isDate: true,
        width: 105,
        restrict: ['Origen', 'Asignar', 'En proceso', 'Por cerrar', 'Completadas']
    },
    /* {
        value: 'checked',
        name: 'Estado',
        index: null,
        checked: false,
        width: 0
    }, */
    {
        value: 'sapId',
        name: 'OM SAP',
        index: null,
        checked: false,
        isDate: false,
        width: 85,
        restrict: ['Origen', 'Asignar', 'En proceso', 'Por cerrar', 'Completadas']
    },
    {
        value: 'site',
        name: 'ID Obra',
        index: null,
        checked: false,
        isDate: false,
        width: 65,
        restrict: ['Origen', 'Asignar', 'En proceso', 'Por cerrar', 'Completadas']
    },
    {
        value: 'urlPdf',
        name: 'Download',
        index: null,
        checked: false,
        isDate: false,
        width: 80,
        restrict: ['Completadas']
    },
    /* {
        value: 'buttons',
        name: 'Acción',
        index: null,
        checked: false,
        isDate: false,
        width: 100
    }, */
]
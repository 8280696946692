import SignatureCanvas from 'react-signature-canvas'

const Canvas = (props) => {

    const { width, height, setRefCanvas, disabled, setIsDraw } = props;

    return (
        <SignatureCanvas 
            onEnd={(e) => {setIsDraw(true)}}
            ref={(ref) => {setRefCanvas(ref)}}
            penColor='black'
            canvasProps={
                /* disabled, */
                {
                    width: width, 
                    height: height, 
                    style: {
                        borderStyle: 'solid',
                        borderWidth: 1,
                        borderColor: '#333',
                        borderRadius: 20
                    }
                }
            } />
    )
}

export default Canvas
import { createContext, useContext, useState } from "react";
import { LoadingLogoDialog } from "../dialogs";

export const LoadingContext = createContext()

export const LoadingProvider = props => {
    const [loadingLogo, setLoadingLogo] = useState(false)
    
    const provider = {
        setLoadingLogo
    }

    return (
        <>
            <LoadingLogoDialog open={loadingLogo} />
            <LoadingContext.Provider value={provider} {...props} />
        </>
    )
}

export const useLoadingContext = () => useContext(LoadingContext)